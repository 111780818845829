import React, {useCallback, useContext, useEffect} from 'react';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/jobsResults.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

function JobsResults({results, setResult, setJob, Navigation, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);

    const Job = data => {
        setResult(results[data]);

        const message = {
            type: 'wss',
            path: 'job',
            action: 'get',
            data: {
                id: results[data].id
            }
        };
        wsCall(message);
    }

    const Item = data => {
        return <li>
            <div className="mbsc-row cs-jobs-results-list">
                {data.item.logo && width > 768 &&
                    <div style={{backgroundImage: "url(" + data.item.logo + ")"}} className="cs-jobs-results-avatar" />
                }
                {!data.item.logo && width > 768 &&
                    <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=identicon')"}} className="cs-jobs-results-avatar" />
                }
                <div className="cs-jobs-results-file">
                    <div className="mbsc-row">
                        <h4 className="cs-jobs-results-file-text">
                            <span dangerouslySetInnerHTML={{__html: data.item.title}} />
                        </h4>
                    </div>
                    <div>
                        <span dangerouslySetInnerHTML={{__html: data.item.account}} />
                    </div>
                </div>
            </div>
        </li>;
    }

    const GetJob = useCallback(data => {
        if (data.job) {
            setJob(data);

            Navigation({tab: 'job', item: 'job', save: true});
        }
    }, [Navigation]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'job') {
            GetJob(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetJob, setWsResponse]);

    return (
        <Listview
            theme="ios"
            themeVariant="light"
            itemType={Item}
            data={results}
            onItemTap={event => Job(event.index)}
        />
    );
}

export default JobsResults;
