import {useCallback, useRef, useState} from 'react';
import {toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import useWebSocket from "react-use-websocket";
import Decode from './Decode';

function Websockets(locale, token, session, wss) {
    const [wsResponse, setWsResponse] = useState(null);
    const loading = useRef(true);

    const {sendJsonMessage} = useWebSocket(wss ? Decode(wss) : null, {
        onOpen: () => {
            toast({message: locale.websocket.b, color: 'info', duration: 1000, display: 'bottom'});
            loading.current = false;
        },
        onClose: () => {},
        onError: () => {
            if (loading.current === false) {
                toast({message: locale.websocket.c, color: 'danger', duration: 3000, display: 'bottom'});
            }},
        onMessage: (event) => {
            const message = JSON.parse(event.data);
            setWsResponse(message);
        },
        retryOnError: true,
        reconnectAttempts: 1000,
        reconnectInterval: 5000,
        share: true
    });

    const wsCall = useCallback((data) => {
        const item = data;
        item.token = token;
        item.session = session;

        sendJsonMessage(item);
    }, [token, session, sendJsonMessage]);

    return {wsResponse, wsCall, setWsResponse};
}

export default Websockets;
