import React, {useState, useContext, useCallback, useEffect} from 'react';
import {toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/jobResponsibilities.css';
import Empty from '../utils/Empty';
import {NetworkContext} from "../utils/NetworkContext";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DayJS from "react-dayjs";

function JobResponsibilities({locale, job, setJob}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [responsibilities, setResponsibilities] = useState(job.responsibilities);
    const [draft, setDraft] = useState(true);

    const Change = data => {
        setResponsibilities(data);

        if (data && data.length % 10 === 0) {
            const item = {...job};
            item.responsibilities = data;
            setJob(item);

            const message = {
                type: 'wss',
                path: 'job',
                action: 'update',
                data: {
                    id: job.id,
                    responsibilities: data
                }
            };
            wsCall(message);
        }
    }

    const Blur = () => {
        const item = {...job};
        item.responsibilities = responsibilities;
        setJob(item);

        const message = {
            type: 'wss',
            path: 'job',
            action: 'update',
            data: {
                id: job.id,
                responsibilities: responsibilities
            }
        };
        wsCall(message);
    }

    const UpdateJob = useCallback(data => {
        if (data.toast && !draft) {
            setDraft(true);
            toast({message: locale.jobResponsibilities.d, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.jobResponsibilities.d, draft]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'job') {
            UpdateJob(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateJob, setWsResponse]);

    return (
        <>
            <div className="cs-job-responsibilities-col-ck">
                <CKEditor
                    id="editor-focus"
                    config={{toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'undo', 'redo']}}
                    editor={ClassicEditor}
                    data={responsibilities}
                    onChange={(event, editor) => Change(editor.getData())}
                    onBlur={() => {Blur(); setDraft(false);}}
                />
            </div>
            <div className="mbsc-row cs-job-responsibilities-footer">
                <div className="mbsc-col cs-job-responsibilities-footer-actions" >
                    <div className="mbsc-txt-muted mbsc-italic">{locale.jobResponsibilities.e}</div>
                </div>
                <div className="mbsc-col-auto cs-job-responsibilities-footer-note" />
            </div>
        </>
    );
}

export default JobResponsibilities;
