import React, {useEffect, useState, useCallback, useContext} from 'react';
import {Accordion} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import './styles/partners.css';
import Empty from './utils/Empty';
import PartnersPartner from './blocks/PartnersPartner';
import {NetworkContext} from "./utils/NetworkContext";

function Partners({width, locale}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [partners, setPartners] = useState([]);

    const Partners = () => {
        const message = {
            type: 'rest',
            path: 'accounts',
            action: 'get',
            data: {}
        };
        restCall(message);
    }

    const GetAccounts = useCallback(data => {
        if (data.partners) {
            setPartners(data.partners);
        }
    }, []);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'accounts') {
            GetAccounts(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetAccounts, setRestResponse]);

    useEffect(() => {
        Partners();
        }, []);

  return (
      <div className="mbsc-grid-lg mbsc-grid-fixed mbsc-no-padding md-grid-cards cs-partners-container">
          {width > 576 &&
              <div className="mbsc-row">
                  <div className="mbsc-col">
                      <Accordion>
                          {!Empty(partners) && partners.col1.length !== 0 && partners.col1.map((partner) => {
                              return (
                                  <PartnersPartner
                                      locale={locale}
                                      partner={partner}
                                  />
                              );
                          })}
                      </Accordion>
                  </div>
                  <div className="mbsc-col">
                      <Accordion>
                          {!Empty(partners) && partners.col2.length !== 0 && partners.col2.map((partner) => {
                              return (
                                  <PartnersPartner
                                      locale={locale}
                                      partner={partner}
                                  />
                              );
                          })}
                      </Accordion>
                  </div>
              </div>
          }
          {width < 576 &&
              <div className="mbsc-row">
                  <div className="mbsc-col">
                      <Accordion>
                          {!Empty(partners) && partners.all.length !== 0 && partners.all.map((partner) => {
                              return (
                                  <PartnersPartner
                                      locale={locale}
                                      partner={partner}
                                  />
                              );
                          })}
                      </Accordion>
                  </div>
              </div>
          }
      </div>
  );
}

export default Partners;
