import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, Input, Checkbox, toast, confirm} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/usersUser.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

function UsersUser({locale, Dialog, dialog, userData, user, Directory}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [firstName, setFirstName] = useState(userData.firstName);
    const [lastName, setLastName] = useState(userData.lastName);
    const [owner, setOwner] = useState(userData.options.owner);
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const CloseDialog = useCallback(() => {
        Directory();
        Dialog({view: 'close'});
    }, [Dialog, Directory]);

    const Save = () => {
        if (password && password !== passwordConfirm) {
            toast({message: locale.usersUser.k, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const item = {...userData.options};
            item.owner = owner;

            const message = {
                type: 'wss',
                path: 'user',
                action: 'update',
                data: {
                    email: userData.email,
                    firstName: firstName,
                    lastName: lastName,
                    options: item,
                    password: password
                }
            };
            wsCall(message);
        }
    }

    const Delete = () => {
        if (userData.email === user.email) {
            toast({message: locale.usersUser.q, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            confirm({
                title: locale.usersUser.m,
                message: locale.usersUser.n,
                okText: locale.usersUser.p,
                cancelText: locale.usersUser.o,
                callback: (res) => {
                    if (res) {
                        const message = {
                            type: 'wss',
                            path: 'user',
                            action: 'delete',
                            data: {
                                user: userData.email
                            }
                        };
                        wsCall(message);
                    }
                }
            });
        }
    }

    const DeleteUser = useCallback(data => {
        if (data.toast) {
            toast({message: locale.usersUser.l, color: 'info', duration: 1000, display: 'bottom'});
            CloseDialog();
        }
    }, [locale.usersUser.l, CloseDialog]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'user') {
            DeleteUser(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteUser, setWsResponse]);

    return (
        <Popup className="cs-users-user-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} theme="ios" scrollLock={false} themeVariant="light" display="center" headerText={userData.email} buttons={[{text: locale.usersUser.i, cssClass: 'cs-users-user-cancel', handler: () => CloseDialog()}, {text: locale.usersUser.j, cssClass: 'cs-users-user-save', handler: () => Delete()}, {text: locale.usersUser.h, cssClass: 'cs-users-user-save', handler: () => Save()}]} isOpen={dialog.view === 'usersUser'} onClose={CloseDialog}>
            <div className="mbsc-row mbsc-justify-content-center">
                <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + userData.avatar + "?d=robohash)"}} className="cs-users-user-avatar" />
            </div>
            <Input disabled={userData.email === user.email} theme="ios" themeVariant="light" inputStyle="underline" label={locale.usersUser.b} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
            <Input disabled={userData.email === user.email} theme="ios" themeVariant="light" inputStyle="underline" label={locale.usersUser.c} labelStyle="floating" type="text" name="lastName" value={lastName} onChange={ev => setLastName(ev.target.value)} />
            {userData.email === user.email &&
                <div className="cs-users-user-helper">
                    <p className="mbsc-bold mbsc-txt-muted">{locale.usersUser.r}</p>
                </div>
            }
            {userData.email !== user.email &&
                <Checkbox
                    theme="ios"
                    themeVariant="light"
                    disabled={!user.options.owner}
                    cssClass="cs-users-user-checkbox mbsc-bold"
                    color="info"
                    position="start"
                    label={locale.usersUser.a}
                    description={locale.usersUser.f}
                    checked={owner}
                    onChange={(ev) => setOwner(ev.target.checked)}
                />
            }
            {userData.email !== user.email &&
                <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.usersUser.d} labelStyle="floating" minLength="8" type="password" name="Password" passwordToggle={true} value={password} onChange={ev => setPassword(ev.target.value)} />
            }
            {userData.email !== user.email &&
                <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.usersUser.e} labelStyle="floating" minLength="8" type="password" name="Confirm" passwordToggle={true} value={passwordConfirm} onChange={ev => setPasswordConfirm(ev.target.value)} />
            }
        </Popup>
    );
}

export default UsersUser;
