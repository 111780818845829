import React, {useCallback, useEffect, useState, useContext} from 'react';
import {toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './styles/dashboard.css';
import Empty from './utils/Empty';
import DashboardHeader from "./blocks/DashboardHeader";
import Jobs from "./pages/Jobs";
import Job from "./pages/Job";
import School from "./pages/School";
import Schools from "./pages/Schools";
import Candidates from "./pages/Candidates";
import User from "./pages/User";
import Users from "./pages/Users";
import {NetworkContext} from "./utils/NetworkContext";

function Dashboard({width, locale, navigation, Navigation, dialog, Dialog, configuration, setLocale, token, wss}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [user, setUser] = useState({});
    const [account, setAccount] = useState({});
    const [draft, setDraft] = useState(true);
    const [userData, setUserData] = useState({});

    const Start = () => {
        const message = {
            type: 'wss',
            path: 'user-login',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const Locale = useCallback(data => {
        if (data === 'es' && data !== window.localStorage.locale) {
            setLocale(configuration.locale.es);
            window.localStorage.locale = 'es';
        } else if (data === 'en' && data !== window.localStorage.locale) {
            setLocale(configuration.locale.en);
            window.localStorage.locale = 'en';
        }
    }, [configuration.locale.es, configuration.locale.en]);

    const GetUserLogin = useCallback(data => {
        if (data.user) {
            setUser(data.user);
            Locale(data.user.locale);
            if (data.user.candidate) {
                Navigation({page: 'jobs', tab: 'search', card: 'search'});
            } else if (!data.user.candidate && !data.user.options.superAdmin) {
                Navigation({page: 'candidates', tab: 'search'});
            } else if (!data.user.candidate && data.user.options.superAdmin) {
                Navigation({page: 'candidates', tab: 'search'});
            }
        }
        if (data.account) {
            setAccount(data.account);
        }
    }, [Locale, Navigation]);

    const UpdateUser = useCallback(data => {
        if (data.toast && navigation.tab === "bio" && !draft) {
            setDraft(true);
            toast({message: locale.dashboard.a, color: 'info', duration: 1000, display: 'bottom'});
        } else if (data.toast && navigation.tab !== "bio") {
            toast({message: locale.dashboard.a, color: 'info', duration: 1000, display: 'bottom'});
        } else if (data.error) {
            toast({message: locale.dashboard.b, color: 'danger', duration: 3000, display: 'bottom'});
        }
        if (data.documents && data.user && data.user.email === user.email) {
            data.user.documents = data.documents;
            setUser(data.user);
        } else if (data.documents && !data.user) {
            const item = {...user};
            item.documents = data.documents;
            setUser(item);
        } else if (data.user && !data.documents && data.user.email === user.email) {
            setUser(data.user);
        }
        if (dialog.view === 'userReset' || dialog.view === 'note') {
            Dialog({view: 'close'});
        }
    }, [locale.dashboard.a, locale.dashboard.b, user, draft, navigation.tab, Dialog, dialog.view]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'user') {
            UpdateUser(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateUser, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'user-login') {
            GetUserLogin(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetUserLogin, setWsResponse]);

    useEffect(() => {
            Start();
        }, []);

  return (
      <>
          {!Empty(user) &&
              <DashboardHeader
                  locale={locale}
                  navigation={navigation}
                  Navigation={Navigation}
                  dialog={dialog}
                  Dialog={Dialog}
                  configuration={configuration}
                  setAccount={setAccount}
                  user={user}
                  setUser={setUser}
                  token={token}
                  wss={wss}
                  setLocale={setLocale}
                  width={width}
                  account={account}
              />
          }
          {navigation.page === 'jobs' &&
              <Jobs
                  width={width}
                  locale={locale}
                  navigation={navigation}
                  Navigation={Navigation}
                  dialog={dialog}
                  Dialog={Dialog}
                  setLocale={setLocale}
                  user={user}
              />
          }
          {navigation.page === 'schools' &&
              <Schools
                  width={width}
                  locale={locale}
                  navigation={navigation}
                  Navigation={Navigation}
                  dialog={dialog}
                  Dialog={Dialog}
                  user={user}
              />
          }
          {navigation.page === 'user' &&
              <User
                  width={width}
                  locale={locale}
                  navigation={navigation}
                  Navigation={Navigation}
                  dialog={dialog}
                  Dialog={Dialog}
                  configuration={configuration}
                  user={user}
                  setUser={setUser}
                  setDraft={setDraft}
              />
          }
          {navigation.page === 'candidates' &&
              <Candidates
                  width={width}
                  locale={locale}
                  navigation={navigation}
                  Navigation={Navigation}
                  dialog={dialog}
                  Dialog={Dialog}
                  setLocale={setLocale}
                  user={user}
                  configuration={configuration}
              />
          }

          {navigation.page === 'job' &&
              <Job
                  width={width}
                  locale={locale}
                  navigation={navigation}
                  Navigation={Navigation}
                  dialog={dialog}
                  Dialog={Dialog}
                  setLocale={setLocale}
                  user={user}
                  account={account}
                  configuration={configuration}
              />
          }
          {navigation.page === 'school' &&
              <School
                  width={width}
                  locale={locale}
                  navigation={navigation}
                  Navigation={Navigation}
                  dialog={dialog}
                  Dialog={Dialog}
                  configuration={configuration}
                  user={user}
                  account={account}
                  setAccount={setAccount}
              />
          }
          {navigation.page === "users" &&
              <Users
                  locale={locale}
                  width={width}
                  Navigation={Navigation}
                  Dialog={Dialog}
                  account={account}
                  dialog={dialog}
                  user={user}
                  navigation={navigation}
                  userData={userData}
                  setUserData={setUserData}
              />
          }
      </>
  );
}

export default Dashboard;
