import React, {useCallback, useContext, useEffect} from 'react';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/candidatesResults.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

function CandidatesResults({results, setResult, width, Navigation, setCandidate}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);

    const Candidate = data => {
        setResult(results[data]);

        const message = {
            type: 'wss',
            path: 'user-candidate',
            action: 'get',
            data: {
                candidate: results[data].email
            }
        };
        wsCall(message);
    }

    const Item = data => {
        return <li key={data.item.email}>
            <div className="mbsc-row cs-candidates-results-list">
                {data.item.avatar && width > 768 &&
                    <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + data.item.avatar + "?d=robohash)"}} className="cs-candidates-results-avatar" />
                }
                {!data.item.avatar && width > 768 &&
                    <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=robohash')"}} className="cs-candidates-results-avatar" />
                }
                <div className="cs-candidates-results-file">
                    <div className="mbsc-row">
                        <h4 className="cs-candidates-results-file-text">
                            <span dangerouslySetInnerHTML={{__html: data.item.firstName}} /> <span dangerouslySetInnerHTML={{__html: data.item.lastName}} />
                        </h4>
                    </div>
                    <div>
                        <span dangerouslySetInnerHTML={{__html: data.item.email}} /> {data.item.country && ' | '}{data.item.country && <span dangerouslySetInnerHTML={{__html: data.item.country}} />}
                    </div>
                </div>
            </div>
        </li>;
    }

    const GetUserCandidate = useCallback(data => {
        if (data.candidate) {
            setCandidate(data);

            Navigation({tab: 'candidate', item: 'information', save: true});
        }
    }, [Navigation]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'user-candidate') {
            GetUserCandidate(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetUserCandidate, setWsResponse]);

    return (
        <Listview
            theme="ios"
            themeVariant="light"
            itemType={Item}
            data={results}
            onItemTap={event => Candidate(event.index)}
        />
    );
}

export default CandidatesResults;
