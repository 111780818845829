import React, {useCallback, useContext, useEffect, useRef} from 'react';
import {toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/schoolHeader.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

function SchoolHeader({locale, account}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const hiddenLogoInput = useRef(null);
    const hiddenCoverInput = useRef(null);

    const Delete = data => {
        const message = {
            type: 'wss',
            path: 'account-image',
            action: 'put',
            data: data === 'logo' ? {school: account.id, logo: true, delete: account.logo} : {school: account.id, cover: true, delete: account.cover}
        };
        wsCall(message);
    }

    const Logo = data => {
        const name = data.target.files[0].name;
        let file = '';

        const reader = new FileReader();
        reader.addEventListener('load', ()=>{
            file = reader.result;

            const message = {
                type: 'wss',
                path: 'account-image',
                action: 'put',
                data: !Empty(account.logo) ? {school: account.id, file: file, name: name, logo: true, delete: account.logo} : {school: account.id, file: file, name: name, logo: true}
            };
            wsCall(message);
        })
        reader.readAsDataURL(data.target.files[0]);
    }

    const Cover = data => {
        const name = data.target.files[0].name;
        let file = '';

        const reader = new FileReader();
        reader.addEventListener('load', ()=>{
            file = reader.result;

            const message = {
                type: 'wss',
                path: 'account-image',
                action: 'put',
                data: !Empty(account.cover) ? {school: account.id, file: file, name: name, cover: true, delete: account.cover} : {school: account.id, file: file, name: name, cover: true}
            };
            wsCall(message);
        })
        reader.readAsDataURL(data.target.files[0]);
    }

    const School = useCallback(data => {
        const message = {
            type: 'wss',
            path: 'school',
            action: 'get',
            data: {
                id: data
            }
        };
        wsCall(message);
    }, [wsCall]);

    const PutAccountImage = useCallback(data => {
        if (data.cover || data.logo) {
            School(account.id);
        }
        if (data.toast) {
            toast({message: locale.schoolHeader.e, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.schoolHeader.e, School, account.id]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'account-image') {
            PutAccountImage(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutAccountImage, setWsResponse]);

    return (
        <>
            {!Empty(account.cover) &&
                <div className="cs-school-header-cover" style={{backgroundImage: `url(${account.cover.link})`}} />
            }
            <div className="mbsc-row mbsc-justify-content-end cs-school-header-section cs-school-header-cover-actions">
                <div className="cs-school-header-link">
                    <a href="#" onClick={(ev) => hiddenCoverInput.current.click()}>
                        {locale.schoolHeader.a}
                    </a>
                </div>
                {!Empty(account.cover) &&
                    <>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <div className="cs-school-header-delete">
                            <a href="#" onClick={(ev) => Delete('cover')}>
                                {locale.schoolHeader.c}
                            </a>
                        </div>
                    </>
                }
            </div>
            <div className="mbsc-row mbsc-justify-content-center cs-school-header-section cs-school-header-logo">
                {!Empty(account.logo) ?
                    <div style={{backgroundImage: "url(" + account.logo.link + ")"}} className="cs-school-header-avatar" />
                    :
                    <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=identicon)"}} className="cs-school-header-avatar" />
                }
            </div>
            <div className="mbsc-row mbsc-justify-content-center cs-school-header-section cs-school-header-logo-actions">
                <div className="cs-school-header-link">
                    <a href="#" onClick={(ev) => hiddenLogoInput.current.click()}>
                        {locale.schoolHeader.b}
                    </a>
                </div>
                {!Empty(account.logo) &&
                    <>
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        <div className="cs-school-header-delete">
                            <a href="#" onClick={(ev) => Delete('logo')}>
                                {locale.schoolHeader.d}
                            </a>
                        </div>
                    </>
                }
            </div>
            <div style={{display: 'none'}}>
                <input type="file" ref={hiddenLogoInput} onChange={Logo} />
                <input type="file" ref={hiddenCoverInput} onChange={Cover} />
            </div>
        </>
    );
}

export default SchoolHeader;
