import React, {useContext, useState} from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/userBio.css';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DayJS from "react-dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSealExclamation} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

function UserBio({locale, width, user, email, setDraft}) {
    const {wsCall} = useContext(NetworkContext);
    const [bio, setBio] = useState(user.bio);

    const Complete = () => {
        if (bio && bio.length !== 0) {
            const text = bio.replace(/<[^>]+>/g, '');
            const trimmedText = text.trim();

            return trimmedText.length;
        } else {
            return 0;
        }
    }

    const Change = data => {
        setBio(data);

        if (data && data.length % 10 === 0) {
            const message = {
                type: 'wss',
                path: 'user',
                action: 'update',
                data: {
                    email: email,
                    bio: data
                }
            };
            wsCall(message);
        }
    }

    const Blur = () => {

        const message = {
            type: 'wss',
            path: 'user',
            action: 'update',
            data: {
                email: email,
                bio: bio
            }
        };
        wsCall(message);
    }

    return (
        <>
            <div className="cs-user-bio-col-ck">
                {Complete() < 150 &&
                    <div className="cs-user-bio-helper mbsc-row">
                        <FontAwesomeIcon className="cs-user-bio-badge-incomplete" icon={faSealExclamation}/> <p className="mbsc-bold mbsc-txt-muted">{locale.userBio.b}</p>&nbsp;<p className="mbsc-txt-muted">{locale.userBio.c}</p>
                    </div>
                }
                <CKEditor
                    id="editor-focus"
                    config={{toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'undo', 'redo']}}
                    editor={ClassicEditor}
                    data={bio}
                    onChange={(event, editor) => Change(editor.getData())}
                    onBlur={() => {Blur(); setDraft(false);}}
                />
            </div>
            <div className="mbsc-row cs-user-bio-footer">
                <div className="mbsc-col cs-user-bio-footer-actions" >
                    <div className="mbsc-txt-muted mbsc-italic">{width > 992 && locale.userBio.f}</div>
                </div>
                <div className="mbsc-col-auto cs-user-bio-footer-note">
                    <div className="mbsc-txt-muted mbsc-italic">{Complete()}{locale.userBio.e}</div>
                </div>
            </div>
        </>
    );
}

export default UserBio;
