import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Input, Button, SegmentedGroup, Segmented, Select, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import dayjs from 'dayjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMagnifyingGlass, faDeleteLeft} from "@fortawesome/pro-duotone-svg-icons";
import '../styles/jobsSearch.css';
import Empty from '../utils/Empty';
import {NetworkContext} from "../utils/NetworkContext";

function JobsSearch({locale, setResults, user, text, setText, filter, setFilter, results, Navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [search, setSearch] = useState(Boolean(user.search.jobs));
    const [found, setFound] = useState(0);
    const [autocomplete, setAutocomplete] = useState([]);

    const Complete = () => {
        let completedItems = 0;
        const totalItems = 5;

        if (user.publish && user.firstName && user.lastName && user.mobile && user.gender && user.ethnicity && user.primaryCitizenship) {
            completedItems++;
        }

        if (user.bio && user.bio.length !== 0) {
            const text = user.bio.replace(/<[^>]+>/g, '');
            const trimmedText = text.trim();

            if (trimmedText.length > 150) {
                completedItems++;
            }
        }

        if ((user.teacher || user.leader) && user.job && user.school && user.division) {
            completedItems++;
        }

        if (user.documents && Array.isArray(user.documents)) {
            if (user.teacher && !user.leader && user.documents.some(item => item.tag === "resume")) {
                completedItems++;
            } else if (user.leader && user.documents.some(item => item.tag === "resume") && user.documents.some(item => item.tag === "philosophy")) {
                completedItems++;
            }
        }

        if (user.referrals && Array.isArray(user.referrals)) {
            const arrayTimestamp = user.referrals.filter(item =>
                item.hasOwnProperty('timestamp') && item.timestamp != null
            );
            if (arrayTimestamp.length >= 1) {
                completedItems++;
            }
        }

        const completionPercentage = (completedItems / totalItems) * 100;

        return Math.round(completionPercentage);
    }

    const Search = () => {
        if (!text || text.length < 2) {
            toast({message: locale.jobsSearch.f, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            setResults([]);
            setFound(0);

            const message = {
                type: 'wss',
                path: 'search',
                action: 'get',
                data: {
                    search: text,
                    domain: 'job'
                }
            };
            wsCall(message);
        }
    }

    const Type = data => {
        setSearch(JSON.parse(data.target.value));
        const itemSearch = {...user.search};
        itemSearch.jobs = JSON.parse(data.target.value);
        Clear();

        const message = {
            type: 'wss',
            path: 'user',
            action: 'update',
            data: {
                email: user.email,
                search: itemSearch
            }
        };
        wsCall(message);
    }

    const Autocomplete = () => {
        const message = {
            type: 'wss',
            path: 'jobs-search',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const Filter = data => {
        setFilter(data);
        const arrayFilter = data;
        let arrayAutocomplete = autocomplete;

        if (arrayFilter.length !== 0) {
            arrayFilter.map(function (itemFilter) {
                if (itemFilter === 'teacher' || itemFilter === 'leader') {
                    arrayAutocomplete = arrayAutocomplete.filter(function (itemComplete) {
                        return itemComplete.role === itemFilter;
                    })
                } else {
                    arrayAutocomplete = arrayAutocomplete.filter(function (itemComplete) {
                        return itemComplete.grade === itemFilter;
                    })
                }
                return itemFilter;
            })
        }
        if (text && arrayAutocomplete.length !== 0) {
            arrayAutocomplete = arrayAutocomplete.filter(item =>
                item.text.toLowerCase().includes(text.toLowerCase())
            );
        }
        if (arrayFilter.length === 0 && !text){
            setResults([]);
        } else {
            setResults(arrayAutocomplete);

        }
    }

    const Suggestion = data => {
        setText(data);

        if (search) {
            const itemValue = data;
            const arrayFilter = filter;
            let arrayAutocomplete = autocomplete;

            if (arrayFilter.length !== 0) {
                arrayFilter.map(function (itemFilter) {
                    if (itemFilter === 'teacher' || itemFilter === 'leader') {
                        arrayAutocomplete = arrayAutocomplete.filter(function (itemComplete) {
                            return itemComplete.role === itemFilter;
                        })
                    } else {
                        arrayAutocomplete = arrayAutocomplete.filter(function (itemComplete) {
                            return itemComplete.grade === itemFilter;
                        })
                    }
                    return itemFilter;
                })
            }
            if (itemValue && arrayAutocomplete.length !== 0) {
                arrayAutocomplete = arrayAutocomplete.filter(item =>
                    item.text.toLowerCase().includes(itemValue.toLowerCase())
                );
            }
            if (!itemValue && filter.length === 0){
                setResults([]);
            } else {
                setResults(arrayAutocomplete);

            }
        }
    };

    const Clear = () => {
        setFilter('');
        setText('');
        setResults([]);
        setFound(0);
    }

    const GetSearch = useCallback(data => {
        if (data.results) {
            data.results = data.results.map(function(item){
                if (item.timestamp){
                    item.timestamp = dayjs(item.timestamp).format('MMMM D, YYYY h:mm A');
                }
                return item;
            })
            setResults(data.results);
            if (data.results.length === 0) {
                toast({message: locale.jobsSearch.g, color: 'danger', duration: 3000, display: 'bottom'});
            }
        }
        if (data.found) {
            setFound(data.found);
        }
    }, [locale.jobsSearch.g]);

    const GetJobsSearch = useCallback(data => {
        if (data.autocomplete) {
            setAutocomplete(data.autocomplete);
        }
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'jobs-search') {
            GetJobsSearch(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetJobsSearch, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'search') {
            GetSearch(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetSearch, setWsResponse]);

    useEffect(() => {
        if (Complete() === 100 ) {
            toast({message: locale.jobsSearch.k, color: 'info', duration: 1000, display: 'bottom'});
            Autocomplete();
        }
    }, []);

    return (
        <>
            {Complete() !== 100 &&
                <div className="mbsc-padding">
                    <p className="mbsc-bold mbsc-txt-muted">{locale.jobsSearch.a} {Complete()}{locale.jobsSearch.b} <a href="#" onClick={() => Navigation({page: 'user', tab: 'information'})}>{locale.jobsSearch.h}</a></p>
                </div>
            }
            {Complete() === 100 &&
                <Input
                    className="cs-jobs-search-field-input"
                    id="dashboardJobsSearchAutocomplete"
                    theme="ios"
                    themeVariant="light"
                    inputStyle="underline"
                    placeholder={search ? locale.jobsSearch.o : locale.jobsSearch.n}
                    labelStyle="floating"
                    type="text"
                    name="search"
                    value={text}
                    onChange={ev => Suggestion(ev.target.value)}
                    onKeyPress={ev => {if (ev.key === 'Enter' && !search) {Search()}}}
                />
            }
            {Complete() === 100 &&
                <div className="mbsc-row cs-jobs-search-footer">
                    <div className="mbsc-col-lg-4 mbsc-col-xl-3 cs-jobs-search-footer-segmented">
                        <SegmentedGroup
                            theme="ios"
                            themeVariant="light"
                            cssClass="cs-jobs-search-footer-menu"
                            name="jobsSearch"
                            color="primary"
                            value={search}
                            onChange={Type}
                        >
                            <Segmented
                                value={true}
                                theme="ios"
                                themeVariant="light"
                            >
                                {locale.jobsSearch.l}
                            </Segmented>
                            <Segmented
                                value={false}
                                theme="ios"
                                themeVariant="light"
                            >
                                {locale.jobsSearch.m}
                            </Segmented>
                        </SegmentedGroup>
                    </div>
                    <div className="mbsc-col cs-jobs-search-footer-filter">
                        {search && autocomplete.length !== 0 &&
                            <Select
                                theme="ios"
                                themeVariant="light"
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="underline"
                                label={locale.jobsSearch.c}
                                labelStyle="floating"
                                rows={locale.jobsSearch.j.length}
                                display="anchored"
                                touchUi={false}
                                selectMultiple
                                value={filter}
                                data={locale.jobsSearch.j}
                                onChange={(event) => Filter(event.value)}
                            />
                        }
                    </div>
                    <div className="mbsc-col-auto">
                        <div className="mbsc-row">
                            <Button disabled={(search && !text && filter.length === 0) || (!search && !text)} color={((search && !text && filter.length === 0) || (!search && !text)) ? 'secondary' : 'primary'} variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Clear()} >
                                <FontAwesomeIcon className="cs-jobs-search-button-icon-send" icon={faDeleteLeft} /> {locale.jobsSearch.d}
                            </Button>
                            {!search &&
                                <Button disabled={text.length === 0} color={(text.length === 0) ? 'secondary' : 'primary'} variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Search()} >
                                    <FontAwesomeIcon className="cs-jobs-search-button-icon-send" icon={faMagnifyingGlass} /> {locale.jobsSearch.e}
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            }
            {Complete() === 100 && search && text.length !== 0 && results.length === 0 &&
                <div className="mbsc-padding">
                    <p className="mbsc-bold mbsc-txt-muted">{locale.jobsSearch.p}</p>
                </div>
            }
        </>
    );
}

export default JobsSearch;
