import React, {useState, useContext} from 'react';
import '../styles/schoolAbout.css';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {NetworkContext} from "../utils/NetworkContext";

function SchoolAbout({locale, account}) {
    const {wsCall} = useContext(NetworkContext);
    const [about, setAbout] = useState(account.about);

    const Change = data => {
        setAbout(data);

        if (data && data.length % 10 === 0) {

            const message = {
                type: 'wss',
                path: 'account',
                action: 'update',
                data: {
                    id: account.id,
                    about: data,
                    silent: true
                }
            };
            wsCall(message);
        }
    }

    const Blur = () => {

        const message = {
            type: 'wss',
            path: 'account',
            action: 'update',
            data: {
                id: account.id,
                about: about
            }
        };
        wsCall(message);
    }

    return (
        <>
            <div className="cs-school-about-col-ck">
                <CKEditor
                    id="editor-focus"
                    config={{toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'undo', 'redo']}}
                    editor={ClassicEditor}
                    data={about}
                    onChange={(event, editor) => Change(editor.getData())}
                    onBlur={() => Blur()}
                />
            </div>
            <div className="mbsc-row cs-school-footer">
                <div className="mbsc-col cs-school-footer-actions" >
                    <div className="mbsc-txt-muted mbsc-italic">{locale.schoolAbout.b}</div>
                </div>
                <div className="mbsc-col-auto cs-school-footer-note" />
            </div>
        </>
    );
}

export default SchoolAbout;
