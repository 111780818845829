import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Input, Select} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/schoolLocation.css';
import {NetworkContext} from "../utils/NetworkContext";

function SchoolLocation({locale, account, configuration}) {
    const {wsCall} = useContext(NetworkContext);
    const [address, setAddress] = useState(account.address);
    const [zip, setZip] = useState(account.zip);
    const [country, setCountry] = useState(account.country);
    const [city, setCity] = useState(account.city);
    const [state, setState] = useState(account.state);
    const [toSave, setToSave] = useState(false);

    const Save = useCallback(() => {
        const message = {
            type: 'wss',
            path: 'account',
            action: 'update',
            data: {
                id: account.id,
                country: country,
                city: city,
                state: state,
                address: address,
                zip: zip
            }
        };
        wsCall(message);
    }, [account.id, country, city, state, address, zip])

    useEffect(() => {
        if (toSave) {
            Save();
            setToSave(false);
        }
    }, [toSave, Save]);

    return (
        <>
            <div className="mbsc-row cs-school-location-main">
                <div className="mbsc-col">
                    <Select
                        theme="ios"
                        themeVariant="light"
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="outline"
                        label={locale.schoolLocation.c}
                        labelStyle="floating"
                        rows={10}
                        display="anchored"
                        touchUi={false}
                        value={country}
                        data={configuration.mobi.countries}
                        onChange={(event) => {setCountry(event.value); setToSave(true);}}
                    />
                    <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.schoolLocation.d} labelStyle="floating" type="text" name="address" value={address} onBlur={Save} onChange={ev => setAddress(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.schoolLocation.e} labelStyle="floating" type="text" name="city" value={city} onBlur={Save} onChange={ev => setCity(ev.target.value)} />
                    {country === 'US' ?
                        <Select
                            theme="ios"
                            themeVariant="light"
                            animation="slide-down"
                            dropdown={false}
                            inputStyle="outline"
                            label={locale.schoolLocation.f}
                            labelStyle="floating"
                            rows={10}
                            display="anchored"
                            touchUi={false}
                            value={state}
                            data={configuration.mobi.states}
                            onChange={(event) => {setState(event.value); setToSave(true);}}
                        />
                        :
                        <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.schoolLocation.f} labelStyle="floating" type="text" name="state" value={state} onBlur={Save} onChange={ev => setState(ev.target.value)} />
                    }
                    <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.schoolLocation.b} labelStyle="floating" type="text" name="zip" value={zip} onBlur={Save} onChange={ev => setZip(ev.target.value)} />
                </div>
            </div>
        </>
    );
}

export default SchoolLocation;
