import React, {useState , useEffect, useRef, useCallback} from 'react';
import {Page} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './styles/app.css';
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Login from './Login';
import Dashboard from './Dashboard';
import Partners from './Partners';
import Empty from './utils/Empty';
import English from './locale/english.json';
import Spanish from './locale/spanish.json';
import {NetworkProvider} from './utils/NetworkProvider';
dayjs.extend(utc);
dayjs.extend(timezone);

const Token = () => {
    let data = window.localStorage.token;

    if (!data) {
        data = '';
    }
    return data;
}

const Session = () => {
    const itemMinutes = dayjs().utcOffset();
    const itemHours = (itemMinutes >= 0 ? '+' : '-') + String(Math.floor(Math.abs(itemMinutes) / 60)).padStart(2, '0') + ':' + String(Math.abs(itemMinutes) % 60).padStart(2, '0');

    return {
        navigator: {
            agent: navigator.userAgent,
            language: navigator.language,
            referrer: document.referrer
        },
        id: Math.floor(Math.random() * 1000000000000),
        version: "1.0",
        app: "eyJhbGciOiJIUzI1NiJ9.RGl2ZXJzaXR5QWRtaW4.h9_F3Rc4W22seNnZOpajImEwN_DN8lfoykbeSLN8tHI",
        timezone: dayjs.tz.guess(),
        offset: itemHours
    };
}

function App() {
    const token = useRef(Token());
    const session = useRef(Session());
    const wss = useRef('');
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [configuration, setConfiguration] = useState({});
    const [locale, setLocale] = useState(English);
    const [reset, setReset] = useState('');
    const [referral, setReferral] = useState('');
    const [registration, setRegistration] = useState('');
    const [navigation, setNavigation] = useState({});
    const [dialog, setDialog] = useState({});

    const Width = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        setHeight(height);
        setWidth(width);
    }

    const Navigation = useCallback(data => {
        const current = {...navigation};
        let back = JSON.parse(JSON.stringify(navigation.back));

        if (data.menu) {
            current.menu = data.menu;
            setNavigation(current);
        } else if (data.back) {
            setNavigation(back[0]);
        } else {
            let view = data.view ? data.view : '';
            let page = data.page ? data.page : '';
            let tab = data.tab ? data.tab : '';
            let item = data.item ? data.item : '';

            if (page && !view) {
                view = current.view;
            } else if (tab && !view && !page) {
                view = current.view;
                page = current.page;
            } else if (item && !view && !page && !tab) {
                view = current.view;
                page = current.page;
                tab = current.tab;
            }

            if (data.save) {
                back.unshift(current);
            }

            const object = {
                menu: current.menu,
                view: view,
                page: page,
                tab: tab,
                item: item,
                back: back
            }
            setNavigation(object);
        }
    }, [navigation]);

    const Dialog = useCallback(data => {
        let update = data;
        let current = dialog;

        let view = '';
        let tab = '';

        if (update.view && update.view === 'close') {
            view = '';
            tab = '';
        } else if (update.view || update.tab) {
            view = current.view;

            if (update.view) {
                view = update.view;
            } else if (current.view) {
                view = current.view;
            }
            if (update.tab) {
                tab = update.tab;
            } else if (current.tab) {
                tab = current.tab;
            }
        }
        let object = {
            view: view,
            tab: tab
        }
        setDialog(object);
    }, [dialog]);

    const Locale = () => {
        if (window.localStorage.locale && window.localStorage.locale === 'en') {
            setLocale(English);
        } else if (window.localStorage.locale && window.localStorage.locale === 'es') {
            setLocale(Spanish);
        } else {
            window.localStorage.locale = 'en';
            setLocale(English);
        }
    };

    useEffect(() => {
            const location = window.location.href;
            const url = new URL(location);

            const itemToken = url.searchParams.get('token');
            if (itemToken) {
                setReset(itemToken);
            }
            const itemRef = url.searchParams.get('ref');
            if (itemRef) {
                setReferral(itemRef);
            }
            const itemRegister = url.searchParams.get('register');
            if (itemRegister) {
                setRegistration('candidate');
            }
            const itemRegisterP = url.searchParams.get('registerp');
            if (itemRegisterP) {
                setRegistration('school');
            }

            Locale();

            const itemPartners = url.searchParams.get('partners');
            if (itemPartners) {
                setNavigation({view: 'partners'});
            } else {
                setNavigation({view: 'login'});
            }

            window.history.replaceState(null, "Diversity Admin", "/");
            document.title = 'diversityadmin';

            Width();
            window.addEventListener('resize', Width);
            return () => window.removeEventListener('resize', Width);
        }, []);

    return (
        <NetworkProvider locale={locale} session={session.current} token={token.current} wss={wss.current}>
            <Page theme="ios"  themeVariant="light">
                {navigation.view === 'login' &&
                    <Login
                        width={width}
                        wss={wss}
                        locale={locale}
                        dialog={dialog}
                        Dialog={Dialog}
                        reset={reset}
                        setReset={setReset}
                        referral={referral}
                        setReferral={setReferral}
                        registration={registration}
                        setRegistration={setRegistration}
                        token={token}
                        setConfiguration={setConfiguration}
                        setNavigation={setNavigation}
                        setDialog={setDialog}
                        Navigation={Navigation}
                        setLocale={setLocale}
                    />
                }
                {!Empty(configuration) && navigation.view === 'dashboard' &&
                    <Dashboard
                        width={width}
                        locale={locale}
                        navigation={navigation}
                        Navigation={Navigation}
                        dialog={dialog}
                        Dialog={Dialog}
                        configuration={configuration}
                        setLocale={setLocale}
                        token={token}
                        wss={wss}
                    />
                }
                {navigation.view === 'partners' &&
                    <Partners
                        width={width}
                        locale={locale}
                    />
                }
            </Page>
        </NetworkProvider>
    );
}

export default App;
