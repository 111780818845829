import React, {useState, useContext, useCallback, useEffect} from 'react';
import {toast, Input} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/jobContact.css';
import Empty from '../utils/Empty';
import {NetworkContext} from "../utils/NetworkContext";

function JobContact({locale, job, setJob}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [firstName, setFirstName] = useState(job.firstName);
    const [lastName, setLastName] = useState(job.lastName);
    const [email, setEmail] = useState(job.email);
    const [url, setUrl] = useState(job.url);

    const Save = () => {
        const item = {...job};
        item.firstName = firstName;
        item.lastName = lastName;
        item.email = email;
        item.url = url;
        setJob(item);

        const message = {
            type: 'wss',
            path: 'job',
            action: 'update',
            data: {
                id: job.id,
                firstName: firstName,
                lastName: lastName,
                email: email,
                url: url
            }
        };
        wsCall(message);
    }

    const UpdateJob = useCallback(data => {
        if (data.toast) {
            toast({message: locale.jobContact.h, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.jobContact.h]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'job') {
            UpdateJob(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateJob, setWsResponse]);

    return (
        <>
            <div className="mbsc-row cs-job-contact-main">
                <div className="mbsc-col">
                    <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.jobContact.c} labelStyle="floating" type="email" name="email" value={email} onBlur={Save} onChange={ev => setEmail(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.jobContact.d} labelStyle="floating" type="text" name="firstName" value={firstName} onBlur={Save} onChange={ev => setFirstName(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.jobContact.e} labelStyle="floating" type="text" name="lastName" value={lastName} onBlur={Save} onChange={ev => setLastName(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.jobContact.f} labelStyle="floating" type="text" name="url" value={url} onBlur={Save} onChange={ev => setUrl(ev.target.value)} />
                </div>
            </div>
        </>
    );
}

export default JobContact;
