import React, {useState, useContext, useCallback, useEffect} from 'react';
import {Checkbox, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/jobNotifications.css';
import Empty from '../utils/Empty';
import {NetworkContext} from "../utils/NetworkContext";

function JobNotifications({locale, job}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [notifyCandidate, setNotifyCandidate] = useState(false);
    const [notifySummary, setNotifySummary] = useState(false);
    const [jobData, setJobData] = useState({});
    const [toSave, setToSave] = useState(false);

    const Save = useCallback(() => {
        const item = {...jobData.notes};
        item.notifyCandidate = notifyCandidate;
        item.notifySummary = notifySummary;

        const message = {
            type: 'wss',
            path: 'job-notes',
            action: 'put',
            data: {
                private: item,
                shared: jobData.notesShared,
                job: job.id,
                school: job.account,
                logo: job.logo,
                name: job.name,
                title: job.title
            }
        };
        wsCall(message);
    }, [job.account, job.id, job.logo, job.name, job.title, jobData.notes, jobData.notesShared, notifyCandidate, notifySummary]);

    const Notifications = () => {

        const message = {
            type: 'wss',
            path: 'job',
            action: 'get',
            data: {
                id: job.id
            }
        };
        wsCall(message);
    }

    const PutJobNotes = useCallback(data => {
        if (data.toast) {
            toast({message: locale.jobNotifications.d, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.jobNotifications.d]);

    const GetJob = useCallback(data => {
        if (data.notes && data.notes.notifyCandidate) {
            setNotifyCandidate(data.notes.notifyCandidate);
        }
        if (data.notes && data.notes.notifySummary) {
            setNotifySummary(data.notes.notifySummary);
        }
        setJobData(data);
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'job') {
            GetJob(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetJob, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'job-notes') {
            PutJobNotes(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutJobNotes, setWsResponse]);

    useEffect(() => {
        if (toSave) {
            Save();
            setToSave(false);
        }
    }, [toSave, Save]);

    useEffect(() => {
        Notifications();
    }, []);

    return (
        <>
            <div className="mbsc-row cs-job-notifications-main">
                <div className="mbsc-col">
                    <Checkbox
                        theme="ios"
                        themeVariant="light"
                        cssClass="cs-job-notifications-checkbox mbsc-bold"
                        color="info"
                        position="start"
                        label={locale.jobNotifications.e}
                        description={locale.jobNotifications.g}
                        checked={notifyCandidate}
                        onChange={(ev) => {setNotifyCandidate(ev.target.checked); setToSave(true);}}
                    />
                    <Checkbox
                        theme="ios"
                        themeVariant="light"
                        cssClass="cs-job-notifications-checkbox mbsc-bold"
                        color="info"
                        position="start"
                        label={locale.jobNotifications.f}
                        description={locale.jobNotifications.h}
                        checked={notifySummary}
                        onChange={(ev) => {setNotifySummary(ev.target.checked); setToSave(true);}}
                    />
                </div>
            </div>
        </>
    );
}

export default JobNotifications;
