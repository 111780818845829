import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Select, toast, Input, SegmentedGroup, Segmented, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDeleteLeft, faMagnifyingGlass} from "@fortawesome/pro-duotone-svg-icons";
import '../styles/candidatesSearch.css';
import Empty from '../utils/Empty';
import {NetworkContext} from "../utils/NetworkContext";

function CandidatesSearch({locale, setResults, user, text, setText, filter, setFilter, results}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [search, setSearch] = useState(user.search.candidates);
    const [found, setFound] = useState(0);
    const [autocomplete, setAutocomplete] = useState([]);

    const Search = () => {
        if (!text || text.length < 2) {
            toast({message: locale.candidatesSearch.f, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            setResults([]);
            setFound(0);

            const message = {
                type: 'wss',
                path: 'search',
                action: 'get',
                data: {
                    search: text,
                    domain: 'candidate'
                }
            };
            wsCall(message);
        }
    }

    const Type = data => {
        setSearch(JSON.parse(data.target.value));
        const itemSearch = {...user.search};
        itemSearch.candidates = JSON.parse(data.target.value);
        Clear();

        const message = {
            type: 'wss',
            path: 'user',
            action: 'update',
            data: {
                email: user.email,
                search: itemSearch
            }
        };
        wsCall(message);
    }

    const Filter = data => {
        setFilter(data);
        const arrayFilter = data;
        let arrayAutocomplete = autocomplete;

        if (arrayFilter.length !== 0) {
            arrayFilter.map(function (itemFilter) {
                if (itemFilter === 'teacher') {
                    arrayAutocomplete = arrayAutocomplete.filter(function (itemComplete) {
                        return itemComplete.teacher === true;
                    })
                } else if (itemFilter === 'leader') {
                    arrayAutocomplete = arrayAutocomplete.filter(function (itemComplete) {
                        return itemComplete.leader === true;
                    })
                } else {
                    arrayAutocomplete = arrayAutocomplete.filter(function (itemComplete) {
                        return itemComplete.division === itemFilter;
                    })
                }
                return itemFilter;
            })
        }
        if (text && arrayAutocomplete.length !== 0) {
            arrayAutocomplete = arrayAutocomplete.filter(item =>
                item.text.toLowerCase().includes(text.toLowerCase())
            );
        }
        if (arrayFilter.length === 0 && !text){
            setResults([]);
        } else {
            setResults(arrayAutocomplete);

        }
    }

    const Suggestion = data => {
        setText(data);

        if (search) {
            const itemValue = data;
            const arrayFilter = filter;
            let arrayAutocomplete = autocomplete;

            if (arrayFilter.length !== 0) {
                arrayFilter.map(function (itemFilter) {
                    if (itemFilter === 'teacher') {
                        arrayAutocomplete = arrayAutocomplete.filter(function (itemComplete) {
                            return itemComplete.teacher === true;
                        })
                    } else if (itemFilter === 'leader') {
                        arrayAutocomplete = arrayAutocomplete.filter(function (itemComplete) {
                            return itemComplete.leader === true;
                        })
                    } else {
                        arrayAutocomplete = arrayAutocomplete.filter(function (itemComplete) {
                            return itemComplete.division === itemFilter;
                        })
                    }
                    return itemFilter;
                })
            }
            if (itemValue && arrayAutocomplete.length !== 0) {
                arrayAutocomplete = arrayAutocomplete.filter(item =>
                    item.text.toLowerCase().includes(itemValue.toLowerCase())
                );
            }
            if (!itemValue && filter.length === 0){
                setResults([]);
            } else {
                setResults(arrayAutocomplete);
            }
        }
    };

    const Clear = () => {
        setFilter('');
        setText('');
        setResults([]);
        setFound(0);
    }

    const Autocomplete = () => {
        const message = {
            type: 'wss',
            path: 'users-search',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const GetSearch = useCallback(data => {
        if (data.results) {
            setResults(data.results);
            if (data.results.length === 0) {
                toast({message: locale.candidatesSearch.g, color: 'danger', duration: 3000, display: 'bottom'});
            }
        }
        if (data.found) {
            setFound(data.found);
        }
    }, [locale.candidatesSearch.g]);

    const GetUsersSearch = useCallback(data => {
        if (data.autocomplete) {
            setAutocomplete(data.autocomplete);
        }
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'users-search') {
            GetUsersSearch(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetUsersSearch, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'search') {
            GetSearch(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetSearch, setWsResponse]);

    useEffect(() => {
        toast({message: locale.candidatesSearch.k, color: 'info', duration: 1000, display: 'bottom'});
        Autocomplete();
    }, []);

    return (
        <>
            <Input
                className="cs-candidates-search-field-input"
                id="dashboardCandidatesSearchAutocomplete"
                theme="ios"
                themeVariant="light"
                inputStyle="underline"
                placeholder={search ? locale.candidatesSearch.o : locale.candidatesSearch.n}
                labelStyle="floating"
                type="text"
                name="search"
                value={text}
                onChange={ev => Suggestion(ev.target.value)}
                onKeyPress={ev => {if (ev.key === 'Enter' && !search) {Search()}}}
            />
            <div className="mbsc-row cs-candidates-search-footer">
                <div className="mbsc-col-lg-4 mbsc-col-xl-3 cs-candidates-search-footer-segmented">
                    <SegmentedGroup
                        theme="ios"
                        themeVariant="light"
                        cssClass="cs-candidates-search-footer-menu"
                        name="candidatesSearch"
                        color="primary"
                        value={search}
                        onChange={Type}
                    >
                        <Segmented
                            value={true}
                            theme="ios"
                            themeVariant="light"
                        >
                            {locale.candidatesSearch.l}
                        </Segmented>
                        <Segmented
                            value={false}
                            theme="ios"
                            themeVariant="light"
                        >
                            {locale.candidatesSearch.m}
                        </Segmented>
                    </SegmentedGroup>
                </div>
                <div className="mbsc-col cs-candidates-search-footer-filter">
                    {search && autocomplete.length !== 0 &&
                        <Select
                            theme="ios"
                            themeVariant="light"
                            animation="slide-down"
                            dropdown={false}
                            inputStyle="underline"
                            label={locale.candidatesSearch.c}
                            labelStyle="floating"
                            rows={locale.candidatesSearch.j.length}
                            display="anchored"
                            touchUi={false}
                            selectMultiple
                            data={locale.candidatesSearch.j}
                            value={filter}
                            onChange={(event) => Filter(event.value)}
                        />
                    }
                </div>
                <div className="mbsc-col-auto">
                    <div className="mbsc-row">
                        <Button disabled={(search && !text && filter.length === 0) || (!search && !text)} color={((search && !text && filter.length === 0) || (!search && !text)) ? 'secondary' : 'primary'} variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Clear()} >
                            <FontAwesomeIcon className="cs-candidates-search-button-icon-send" icon={faDeleteLeft} /> {locale.jobsSearch.d}
                        </Button>
                        {!search &&
                            <Button disabled={text.length === 0} color={(text.length === 0) ? 'secondary' : 'primary'} variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Search()} >
                                <FontAwesomeIcon className="cs-candidates-search-button-icon-send" icon={faMagnifyingGlass} /> {locale.candidatesSearch.e}
                            </Button>
                        }
                    </div>
                </div>
            </div>
            {search && text.length !== 0 && results.length === 0 &&
                <div className="mbsc-padding">
                    <p className="mbsc-bold mbsc-txt-muted">{locale.candidatesSearch.g}</p>
                </div>
            }
        </>
    );
}

export default CandidatesSearch;
