import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Popup, SegmentedGroup, Segmented, Input, Checkbox, toast, alert} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/loginRegister.css';
import Empty from '../utils/Empty';
import {NetworkContext} from "../utils/NetworkContext";

function LoginRegister({locale, Dialog, dialog, registration, setRegistration}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [step, setStep] = useState(0);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState(550);
    const [teacher, setTeacher] = useState(true);
    const [leader, setLeader] = useState(false);
    const [school, setSchool] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [ccName, setCcName] = useState('');
    const [ccNumber, setCcNumber] = useState('');
    const [ccExpiration, setCcExpiration] = useState('');
    const [ccCvc, setCcCvc] = useState('');
    const [coupon, setCoupon] = useState('');
    const [processing, setProcessing] = useState(false);

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Header = () => {
        if (step === 0) {

            return locale.loginRegister.be;
        } else if (step !== 0 && registration === 'school') {

            return locale.loginRegister.r;
        } else if (step !== 0 && registration === 'candidate') {

            return locale.loginRegister.s;
        }
    }

    const Buttons = () => {
        if (step === 0) {

            return [{
                    text: locale.loginRegister.ak,
                    cssClass: 'cs-login-register-cancel',
                    handler: 'close'
                }, {
                text: locale.loginRegister.w,
                    cssClass: 'cs-login-register-next',
                    handler: ()=> Next()
                }]
        } else if (step === 1 && registration === 'candidate') {

            return [{
                text: locale.loginRegister.c,
                cssClass: 'cs-login-register-next',
                handler: ()=> Back()
            },{
                text: locale.loginRegister.k,
                cssClass: 'cs-login-register-next',
                handler: ()=> Finish()
            }]
        } else if (step === 1 && registration === 'school') {

            return [{
                text: locale.loginRegister.c,
                cssClass: 'cs-login-register-next',
                handler: ()=> Back()
            },{
                text: locale.loginRegister.b,
                cssClass: 'cs-login-register-next',
                handler: ()=> UserCheck()
            }]
        } else if (step === 2 && registration === 'school') {

            return [{
                text: locale.loginRegister.c,
                cssClass: 'cs-login-register-next',
                handler: ()=> Back()
            },{
                text: locale.loginRegister.b,
                cssClass: 'cs-login-register-next',
                handler: ()=> CouponCheck()
            }]
        } else if (step === 3 && registration === 'school') {

            return [{
                text: locale.loginRegister.c,
                cssClass: 'cs-login-register-next',
                handler: ()=> Back()
            },{
                text: locale.loginRegister.k,
                cssClass: 'cs-login-register-next',
                handler: ()=> Finish()
            }]
        }
    }

    const Tab = data => {
        setRegistration(data.target.value);
    }

    const Back = () => {
        if (step !== 0) {
            let item= step - 1;
            setStep(item);
        }
    }

    const UserCheck = () => {
        if (!school || !firstName || !lastName || !email || !password || !passwordConfirm) {

            toast({message: locale.loginRegister.o, color: 'danger', duration: 3000, display: 'bottom'});

        } else if (password !== passwordConfirm) {

            toast({message: locale.loginRegister.a, color: 'danger', duration: 3000, display: 'bottom'});

        } else {

            const message = {
                type: 'rest',
                path: 'user',
                action: 'get',
                data: {
                    email: email
                }
            };
            restCall(message);
        }
    }

    const CouponCheck = () => {
        const string = ccNumber.replaceAll(' ', '');

        if (!ccName) {

            toast({ message: locale.loginRegister.aq, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (string.length !== 16) {

            toast({ message: locale.loginRegister.ap, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (string && isNaN(string)) {

            toast({ message: locale.loginRegister.ap, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (!ccExpiration) {

            toast({ message: locale.loginRegister.ar, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (ccExpiration.length !== 5) {

            toast({ message: locale.loginRegister.ar, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (!ccCvc) {

            toast({ message: locale.loginRegister.ar, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (ccCvc && isNaN(ccCvc)) {

            toast({ message: locale.loginRegister.ar, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (coupon) {

            const message = {
                type: 'rest',
                path: 'coupon',
                action: 'get',
                data: {
                    coupon: coupon
                }
            };
            restCall(message);
        } else {
            let item= step + 1;
            setStep(item);
        }
    }

    const Finish = () => {

        if (processing) {

        } else if (registration === 'candidate') {

            if (!firstName || !lastName || !email || !password || !passwordConfirm) {
                toast({message: locale.loginRegister.o, color: 'danger', duration: 3000, display: 'bottom'});
            } else if (password !== passwordConfirm) {
                toast({message: locale.loginRegister.a, color: 'danger', duration: 3000, display: 'bottom'});
            } else {

                setProcessing(true);

                const message = {
                    type: 'rest',
                    path: 'user',
                    action: 'put',
                    data: {
                        email: email,
                        type: registration,
                        firstName: firstName,
                        lastName: lastName,
                        password: password,
                        teacher: teacher,
                        leader: leader
                    }
                };
                restCall(message);
            }
        } else if (registration === 'school') {

            const string = ccNumber.replaceAll(' ', '');

            toast({message: locale.loginRegister.av, color: 'info', duration: 3000, display: 'bottom'});

            setProcessing(true);

            const message = {
                type: 'rest',
                path: 'user',
                action: 'put',
                data: {
                    number: string,
                    name: ccName,
                    expiration: ccExpiration,
                    cvc: ccCvc,
                    coupon: coupon ? coupon : null,
                    email: email,
                    type: registration,
                    firstName: firstName,
                    lastName: lastName,
                    password: password,
                    school: school
                }
            };
            restCall(message);
        }
    }

    const CC = data => {
        const string = data.replaceAll(' ', '');

        if (string.length <= 4) {
            setCcNumber(string);
        } else if (string.length > 4 && string.length <= 8) {
            const item = string.slice(0, 4) + ' ' + string.slice(4);
            setCcNumber(item);
        } else if (string.length > 8 && string.length <= 12) {
            const item = string.slice(0, 4) + ' ' + string.slice(4, 8) + ' ' + string.slice(8, 12) ;
            setCcNumber(item);
        } else if (string.length > 12 && string.length <= 16) {
            const item = string.slice(0, 4) + ' ' + string.slice(4, 8) + ' ' + string.slice(8, 12) + ' ' + string.slice(12, 16) ;
            setCcNumber(item);
        }
    }

    const Expiration = data => {
        const string = data.replaceAll('/', '');

        if (string.length < 2) {
            setCcExpiration(string);
        } else if (string.length === 2) {
            setCcExpiration(string + '/');
        } else if (string.length > 2 && string.length <= 4) {
            const item = string.slice(0, 2) + '/' + string.slice(2);
            setCcExpiration(item);
        }
    }

    const CVC = data => {
        if (data.length <= 4) {
            setCcCvc(data);
        }
    }

    const Discount = () => {
        Dialog({view: 'loginDiscount'});
    }

    const Next = useCallback(() => {
        let item= step + 1;
        setStep(item);
    }, [step]);

    const GetCoupon = useCallback(data => {
        if (data.next) {
            Next();
        }
        if (data.amount) {
            setAmount(data.amount);
        }
        if (data.error) {
            toast({message: locale.loginRegister.bg, color: 'danger', duration: 3000, display: 'bottom'});
            setCoupon('');
        }
    }, [Next, locale.loginRegister.bg]);

    const GetUser = useCallback(data => {
        if (data.next) {
            Next();
        }
        if (data.error) {
            toast({message: locale.loginRegister.p, color: 'danger', duration: 3000, display: 'bottom'});
        }
    }, [Next, locale.loginRegister.p]);

    const PutUser = useCallback(data => {
        setProcessing(false);

        if (data.toast && registration === 'candidate') {

            alert({
                title: locale.loginRegister.at,
                message: locale.loginRegister.aw,
                okText: locale.loginRegister.au,
                callback: () => {
                    setRegistration('');
                    CloseDialog();
                }
            });
        }
        if (data.stripe && registration === 'school') {

            let message = locale.loginRegister.ay;

            if (data.warning) {
                message = locale.loginRegister.bq
            }

            alert({
                title: locale.loginRegister.at,
                message: message,
                okText: locale.loginRegister.au,
                callback: () => {
                    setRegistration('');
                    CloseDialog();
                }
            });
        }
        if (data.error && data.user) {
            toast({message: locale.loginRegister.p, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (data.error && data.coupon) {
            toast({message: locale.loginRegister.bg, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (data.error && !data.user && !data.coupon) {
            toast({message: locale.loginRegister.q, color: 'danger', duration: 3000, display: 'bottom'});
        }
    }, [CloseDialog, locale.loginRegister.bg, locale.loginRegister.p, locale.loginRegister.q, locale.loginRegister.au, locale.loginRegister.at, locale.loginRegister.aw, locale.loginRegister.ay, registration]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'put' && restResponse.path === 'user') {
            PutUser(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, PutUser, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'user') {
            GetUser(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetUser, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'coupon') {
            GetCoupon(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetCoupon, setRestResponse]);

    return (
        <Popup className="cs-login-register-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} maxHeight={800} theme="ios" themeVariant="light" display="center" scrollLock={false} headerText={Header()} buttons={Buttons()} isOpen={dialog.view === 'loginRegister'} onClose={CloseDialog}>
            {step === 0 &&
                <>
                    <SegmentedGroup theme="ios" themeVariant="light" className="cs-login-register-nav" name="loginRegister" color="primary" value={registration} onChange={Tab}>
                        <Segmented value="candidate" theme="ios" themeVariant="light" >
                            {locale.loginRegister.s}
                        </Segmented>
                        <Segmented value="school" theme="ios" themeVariant="light" >
                            {locale.loginRegister.r}
                        </Segmented>
                    </SegmentedGroup>
                    {registration === 'school' &&
                        <div className="mbsc-padding">
                            <p className="mbsc-txt-muted mbsc-bold">
                                {locale.loginRegister.bd}
                            </p>
                            <ul>
                                <li className="mbsc-txt-muted">
                                    {locale.loginRegister.e}
                                </li>
                                <li className="mbsc-txt-muted">
                                    {locale.loginRegister.f}
                                </li>
                                <li className="mbsc-txt-muted">
                                    {locale.loginRegister.g}
                                </li>
                                <li className="mbsc-txt-muted">
                                    {locale.loginRegister.h}
                                </li>
                            </ul>
                            <p className="mbsc-txt-muted mbsc-bold">
                                {locale.loginRegister.t}{amount.toFixed(2)} {locale.loginRegister.l}
                            </p>
                            <p className="mbsc-txt-muted">
                                {locale.loginRegister.u}
                            </p>
                            <p className="mbsc-txt-muted">
                                {locale.loginRegister.v} <a href="#" onClick={Discount}>{locale.loginRegister.ac}</a>
                            </p>
                        </div>
                    }
                    {registration === 'candidate' &&
                        <div className="mbsc-padding">
                            <p className="mbsc-txt-muted mbsc-bold">
                                {locale.loginRegister.y}
                            </p>
                            <ul>
                                <li className="mbsc-txt-muted">
                                    {locale.loginRegister.z}
                                </li>
                                <li className="mbsc-txt-muted">
                                    {locale.loginRegister.aa}
                                </li>
                                <li className="mbsc-txt-muted">
                                    {locale.loginRegister.ab}
                                </li>
                            </ul>
                        </div>
                    }
                </>
            }
            {step === 1 &&
                <>
                    <div className="cs-login-register-helper">
                        <p className="mbsc-txt-muted mbsc-bold">
                            {locale.loginRegister.i}
                        </p>
                    </div>
                    {registration === 'school' &&
                        <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRegister.x} labelStyle="floating" type="text" name="school" value={school} onChange={ev => setSchool(ev.target.value)} />
                    }
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRegister.ag} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRegister.ah} labelStyle="floating" type="text" name="lastName" value={lastName} onChange={ev => setLastName(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRegister.af} labelStyle="floating" type="text" name="email" value={email} onChange={ev => setEmail(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRegister.ai} labelStyle="floating" type="password" passwordToggle={true} minLength="8" name="password" value={password} onChange={ev => setPassword(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRegister.aj} labelStyle="floating" type="password" passwordToggle={true} minLength="8" name="passwordConfirm" value={passwordConfirm} onChange={ev => setPasswordConfirm(ev.target.value)} />
                    {registration === 'candidate' &&
                        <>
                            <Checkbox
                                theme="ios"
                                themeVariant="light"
                                cssClass="cs-login-register-checkbox mbsc-bold"
                                color="info"
                                position="start"
                                label={locale.loginRegister.ba}
                                description={locale.loginRegister.bb}
                                checked={teacher}
                                onChange={(ev) => setTeacher(ev.target.checked)}
                            />
                            <Checkbox
                                theme="ios"
                                themeVariant="light"
                                cssClass="cs-login-register-checkbox mbsc-bold"
                                color="info"
                                position="start"
                                label={locale.loginRegister.az}
                                description={locale.loginRegister.bc}
                                checked={leader}
                                onChange={(ev) => setLeader(ev.target.checked)}
                            />
                            <div className="cs-login-register-helper">
                                <p className="mbsc-txt-muted">
                                    {locale.loginRegister.bn} <a href="https://www.diversityfair.org/tcpp" target="_blank">{locale.loginRegister.ae}</a>
                                </p>
                            </div>
                        </>
                    }
                </>
            }
            {step === 2 &&
                <>
                    <div className="cs-login-register-helper">
                        <p className="mbsc-txt-muted mbsc-bold">
                            {locale.loginRegister.j}
                        </p>
                    </div>
                    <div className="cs-login-register-helper">
                        <h5 className="mbsc-txt-muted cs-login-register-group-title-text">
                            {locale.loginRegister.as}
                        </h5>
                    </div>
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRegister.al} placeholder={locale.loginRegister.bi} labelStyle="floating" type="text" name="ccName" value={ccName} onChange={ev => setCcName(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRegister.am} placeholder={locale.loginRegister.bj} labelStyle="floating" type="text" name="ccNumber" value={ccNumber} onChange={ev => CC(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRegister.an} placeholder={locale.loginRegister.bl} labelStyle="floating" type="text" name="ccExpiration" value={ccExpiration} onChange={ev => Expiration(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRegister.ao} placeholder={locale.loginRegister.bk} labelStyle="floating" type="text" name="ccCvc" value={ccCvc} onChange={ev => CVC(ev.target.value)} />
                    <div className="cs-login-register-helper">
                        <p className="mbsc-txt-muted">
                            {locale.loginRegister.bh}
                        </p>
                    </div>
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRegister.bf} labelStyle="floating" type="text" name="coupon" value={coupon} onChange={ev => setCoupon(ev.target.value)} />
                </>
            }
            {step === 3 &&
                <>
                    <div className="cs-login-register-helper">
                        <p className="mbsc-txt-muted mbsc-bold">
                            {coupon ? locale.loginRegister.bm : locale.loginRegister.bp}{amount.toFixed(2)} {locale.loginRegister.l}.
                        </p>
                    </div>
                    <div className="cs-login-register-helper">
                        <p className="mbsc-txt-muted">
                            {locale.loginRegister.ad}
                        </p>
                    </div>
                    <div className="cs-login-register-helper">
                        <p className="mbsc-txt-muted">
                            {locale.loginRegister.bo} <a href="https://www.diversityfair.org/tcpp" target="_blank">{locale.loginRegister.ae}</a>
                        </p>
                    </div>
                </>
            }
        </Popup>
    );
}

export default LoginRegister;
