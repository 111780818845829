import React, {useState, useContext, useCallback, useEffect} from 'react';
import {Input, Select, toast, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLayerPlus} from "@fortawesome/pro-duotone-svg-icons";
import '../styles/jobNew.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";

function JobNew({locale, Navigation, setJob}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [title, setTitle] = useState('');
    const [type, setType] = useState('fullTime');
    const [grade, setGrade] = useState('allSchool');
    const [role, setRole] = useState('teacher');

    const Save = () => {
        if (!title) {
            toast({message: locale.jobNew.b, color: 'danger', duration: 3000, display: 'bottom'});
        } else {

            const message = {
                type: 'wss',
                path: 'job',
                action: 'put',
                data: {
                    title: title,
                    type: type,
                    grade: grade,
                    role: role
                }
            };
            wsCall(message);
        }
    }

    const PutJob = useCallback(data => {
        if (data.toast) {
            toast({message: locale.jobNew.q, color: 'info', duration: 1000, display: 'bottom'});
        }
        if (data.job) {
            setJob(data.job);
            Navigation({tab: 'job', save: true});
        }
    }, [locale.jobNew.q, Navigation]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'job') {
            PutJob(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutJob, setWsResponse]);

    return (
        <>
            <div className="mbsc-row cs-job-new-main">
                <div className="mbsc-col">
                    <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.jobNew.a} labelStyle="floating" type="text" name="title" value={title} onChange={ev => setTitle(ev.target.value)} />
                    <Select
                        theme="ios"
                        themeVariant="light"
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="outline"
                        label={locale.jobNew.d}
                        labelStyle="floating"
                        rows={locale.jobNew.r.length}
                        display="anchored"
                        touchUi={false}
                        value={type}
                        data={locale.jobNew.r}
                        onChange={(event) => setType(event.value)}
                    />
                    <Select
                        theme="ios"
                        themeVariant="light"
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="outline"
                        label={locale.jobNew.i}
                        labelStyle="floating"
                        rows={locale.jobNew.s.length}
                        display="anchored"
                        touchUi={false}
                        value={grade}
                        data={locale.jobNew.s}
                        onChange={(event) => setGrade(event.value)}
                    />
                    <Select
                        theme="ios"
                        themeVariant="light"
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="outline"
                        label={locale.jobNew.n}
                        labelStyle="floating"
                        rows={locale.jobNew.t.length}
                        display="anchored"
                        touchUi={false}
                        value={role}
                        data={locale.jobNew.t}
                        onChange={(event) => setRole(event.value)}
                    />
                </div>
            </div>
            <div className="mbsc-row cs-job-new-footer">
                <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Save()} >
                    <FontAwesomeIcon className="cs-job-new-icon-upload" icon={faLayerPlus} /> {locale.jobNew.c}
                </Button>
            </div>
        </>
    );
}

export default JobNew;
