import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, Textarea, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderCancel.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

function DashboardHeaderCancel({Dialog, dialog, locale, user}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [text, setText] = useState('');

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Submit = () => {
        if (!text) {
            toast({message: locale.dashboardHeaderCancel.a, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const message = {
                type: 'wss',
                path: 'help-cancel',
                action: 'put',
                data: {
                    text: text
                }
            };
            wsCall(message);
        }
    }

    const PutHelpCancel = useCallback(data => {
        if (data.toast) {
            toast({message: locale.dashboardHeaderCancel.b, color: 'info', duration: 1000, display: 'bottom'});
            CloseDialog()
        }
    }, [locale.dashboardHeaderCancel.b, CloseDialog]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'help-cancel') {
            PutHelpCancel(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutHelpCancel, setWsResponse]);

    return (
        <Popup className="cs-dashboard-header-cancel-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} theme="ios" themeVariant="light" display="center" scrollLock={false} headerText={locale.dashboardHeaderCancel.e} buttons={[{text: locale.dashboardHeaderCancel.f, cssClass: 'cs-dashboard-header-cancel-cancel', handler: ()=> CloseDialog()},{text: locale.dashboardHeaderCancel.d, cssClass: 'cs-dashboard-header-cancel-save', handler: () => Submit()}]} isOpen={dialog.view === 'dashboardHeaderCancel'} onClose={CloseDialog}>
            <div className="cs-dashboard-header-cancel-helper">
                <p className="mbsc-txt-muted">
                    {locale.dashboardHeaderCancel.g}
                </p>
            </div>
            <div className="cs-dashboard-header-cancel-textarea">
                <Textarea theme="ios" themeVariant="light" inputStyle="underline" labelStyle="floating" placeholder={locale.dashboardHeaderCancel.h} label={locale.dashboardHeaderCancel.c} value={text} onChange={(ev) => setText(ev.target.value)} />
            </div>
        </Popup>
    );
}

export default DashboardHeaderCancel;
