import React from "react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/navigationItem.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSealExclamation, faBadgeCheck} from "@fortawesome/pro-duotone-svg-icons";

function Item(data, navigation, Complete) {
    const completeCheck = typeof Complete === 'function';

    return <li data-selected={data.item.name === navigation.tab}>
        {data.item.name === 'back' && <FontAwesomeIcon icon={faArrowLeft} />} <span className="mbsc-bold mbsc-txt-muted">{data.item.label}</span> {navigation.page === 'user' && data.item.name !== "spouse" && completeCheck && (
        <>
            {!Complete(data.item.name) && (
                <FontAwesomeIcon
                    className="cs-navigation-item-badge-incomplete"
                    icon={faSealExclamation}
                />
            )}
            {Complete(data.item.name) && (
                <FontAwesomeIcon
                    className="cs-navigation-item-badge-complete"
                    icon={faBadgeCheck}
                />
            )}
        </>
    )}
    </li>;
}

export default Item;
