import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Button, Segmented, SegmentedGroup, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/sharedSchool.css';
import DayJS from 'react-dayjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHeartCirclePlus, faHeartCircleMinus} from "@fortawesome/pro-duotone-svg-icons";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title} from 'chart.js';
import {Doughnut, Bar} from 'react-chartjs-2';
import Empty from '../utils/Empty';
import SchoolImage from "../dialogs/SchoolImage";
import {NetworkContext} from "../utils/NetworkContext";

function School({locale, school, Dialog, dialog, schoolData, Navigation, navigation, setJob, width}) {
    ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [image, setImage] = useState('');
    const [favorite, setFavorite] = useState(schoolData.notes.favorite);
    const [notes, setNotes] = useState(schoolData.notes);

    const Tab = data => {
        Navigation({item: data.target.value});
    }

    const Section = data => {
        if (width >= 576 && data === 'left') {

            return "mbsc-col-12 mbsc-col-sm-6 mbsc-col-md-6 mbsc-col-lg-6 mbsc-col-xl-4 cs-shared-school-card-section-left";
        } else if (width >= 1200 && data === 'middle') {

            return "mbsc-col-12 mbsc-col-sm-6 mbsc-col-md-6 mbsc-col-lg-6 mbsc-col-xl-4 cs-shared-school-card-section-middle";
        } else if ((width >= 1200 && data === 'right') || (width >= 576 && width < 1200 && data === 'middle')) {

            return "mbsc-col-12 mbsc-col-sm-6 mbsc-col-md-6 mbsc-col-lg-6 mbsc-col-xl-4 cs-shared-school-card-section-right";
        } else if ((width < 1200 && data === 'right') || (width < 576 && data === 'middle')) {

            return "mbsc-col-12 mbsc-col-sm-6 mbsc-col-md-6 mbsc-col-lg-6 mbsc-col-xl-4 cs-shared-school-card-section";
        } else if (width < 576 && data === 'left') {

            return "mbsc-col-12 mbsc-col-sm-6 mbsc-col-md-6 mbsc-col-lg-6 mbsc-col-xl-4";
        }
    }

    const Image = data => {
        setImage(data.link);
        Dialog({view: 'schoolsSchoolImage'});
    }

    const Notes = data => {
        const item = {...notes};
        if (data === 'favorite') {
            if (item.favorite === false) {
                item.favorite = true;
                setFavorite(true);
            } else {
                item.favorite = false;
                setFavorite(false);
            }
        }
        setNotes(item);

        const message = {
            type: 'wss',
            path: 'account-notes',
            action: 'put',
            data: {
                private: item,
                shared: schoolData.notesShared,
                country: school.country,
                state: school.state,
                logo: school.logo.link,
                name: school.name,
                city: school.city,
                account: school.id
            }
        };
        wsCall(message);
    }

    const Job = data => {
        const message = {
            type: 'wss',
            path: 'job',
            action: 'get',
            data: {
                id: schoolData.jobs[data].id
            }
        };
        wsCall(message);
    }

    const Item = data => {
        return <li>
            <div className="cs-shared-school-list">
                <h4 className="cs-shared-school-file-text">
                    {data.item.title}
                </h4>
                <div>
                    {data.item.grade}
                </div>
            </div>
        </li>;
    }

    const GetJob = useCallback(data => {
        if (data.job) {
            setJob(data);

            Navigation({tab: 'job', item: 'job', save: true});
        }
    }, [Navigation]);

    const PutAccountNotes = useCallback(data => {
        if (data.toast) {
            toast({message: locale.sharedSchool.k, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.sharedSchool.k]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'account-notes') {
            PutAccountNotes(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutAccountNotes, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'job') {
            GetJob(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetJob, setWsResponse]);

    useEffect(() => {
        if (navigation.menu !== locale.sharedSchool.l) {
            Navigation({menu: locale.sharedSchool.l});
        } else if (!navigation.item) {
            Navigation({item: 'school'});
        }
    }, []);

    return (
        <>
            {!Empty(school.cover) &&
                <div className="cs-shared-school-cover" style={{backgroundImage: `url(${school.cover.link})`}} />
            }
            <div className="mbsc-row cs-shared-school-main">
                {!Empty(school.logo) ?
                    <div style={{backgroundImage: "url(" + school.logo.link + ")"}} className="cs-shared-school-avatar" />
                    :
                    <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=identicon')"}} className="cs-shared-school-avatar" />
                }
                <div>
                    <div>
                        <h4 className="cs-shared-school-header-title">{school.name}</h4>
                    </div>
                    <div>
                        {school.country &&
                            <span dangerouslySetInnerHTML={{__html: school.country}} />
                        }
                        {school.city &&
                            <span> | </span>
                        }
                        {school.city &&
                            <span dangerouslySetInnerHTML={{__html: school.city}} />
                        }
                        {school.state &&
                            <span> | </span>
                        }
                        {school.state &&
                            <span dangerouslySetInnerHTML={{__html: school.state}} />
                        }
                    </div>
                </div>
            </div>
            <div className="mbsc-row cs-shared-school-main">
                <div className="mbsc-col">
                    <SegmentedGroup theme="ios" themeVariant="light" className="cs-shared-school-nav" name="schoolsSchool" color="primary" value={navigation.item} onChange={Tab}>
                        <Segmented value="school" theme="ios" themeVariant="light" >
                            {locale.sharedSchool.e}
                        </Segmented>
                        {school.about &&
                            <Segmented value="about" theme="ios" themeVariant="light" >
                                {locale.sharedSchool.f}
                            </Segmented>
                        }
                        {schoolData.jobs.length !== 0 &&
                            <Segmented value="jobs" theme="ios" themeVariant="light" >
                                {locale.sharedSchool.g}
                            </Segmented>
                        }
                        {school.gallery.col1.length !== 0 &&
                            <Segmented value="gallery" theme="ios" themeVariant="light" >
                                {locale.sharedSchool.h}
                            </Segmented>
                        }
                    </SegmentedGroup>
                </div>
            </div>
            {navigation.item === 'school' &&
                <div className="mbsc-row cs-shared-school-main">
                    <div className="mbsc-col">
                        <div className="mbsc-row mbsc-justify-content-center cs-shared-school-card-section">
                            <div className={Section('left')}>
                                {school.students ?
                                    <div className="cs-shared-school-card mbsc-padding">
                                        <div className="cs-shared-school-text"><h4 className="mbsc-txt-muted cs-shared-school-text-p">{locale.sharedSchool.m}</h4></div>
                                        <div className="cs-shared-school-text"><h4 className="mbsc-txt-muted cs-shared-school-text-p">{school.students}</h4></div>
                                    </div>
                                    :
                                    <div className="cs-shared-school-card-empty">
                                        <div className="cs-shared-school-text"><h4 className="mbsc-txt-muted cs-shared-school-text-p">{locale.sharedSchool.n}</h4></div>
                                    </div>
                                }
                            </div>
                            <div className={Section('middle')}>
                                {school.founded ?
                                    <div className="cs-shared-school-card mbsc-padding">
                                        <div className="cs-shared-school-text"><h4 className="mbsc-txt-muted cs-shared-school-text-p">{locale.sharedSchool.o}</h4></div>
                                        <div className="cs-shared-school-text"><h4 className="mbsc-txt-muted cs-shared-school-text-p"><DayJS format="YYYY">{school.founded}</DayJS></h4></div>
                                    </div>
                                    :
                                    <div className="cs-shared-school-card-empty">
                                        <div className="cs-shared-school-text"><h4 className="mbsc-txt-muted cs-shared-school-text-p">{locale.sharedSchool.p}</h4></div>
                                    </div>
                                }
                            </div>
                            <div className={Section('right')}>
                                <div className="cs-shared-school-card mbsc-padding">
                                    <div className="cs-shared-school-text"><h4 className="mbsc-txt-muted cs-shared-school-text-p">{locale.sharedSchool.q}</h4></div>
                                    <div className="cs-shared-school-text"><h4 className="mbsc-txt-muted cs-shared-school-text-p">{school.lgbtqia ? 'Yes' : 'No'}</h4></div>
                                </div>
                            </div>
                        </div>
                        <div className="mbsc-row mbsc-justify-content-between cs-shared-school-card-section">
                            <div className="mbsc-col-6 cs-shared-school-card-section-left">
                                <div className="cs-shared-school-card mbsc-padding">
                                    {school.stats.leadershipEthnicity.data.labels.length !== 0 ?
                                        <Bar options={school.stats.leadershipEthnicity.options} data={school.stats.leadershipEthnicity.data} />
                                        :
                                        <div className="cs-shared-school-text">
                                            <h4 className="mbsc-txt-s mbsc-txt-muted cs-shared-school-text-p">
                                                {locale.sharedSchool.a}
                                            </h4>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="mbsc-col-6 cs-shared-school-card-section-right">
                                <div className="cs-shared-school-card mbsc-padding">
                                    {school.stats.leadershipGender.data.labels.length !== 0 ?
                                        <Doughnut options={school.stats.leadershipGender.options} data={school.stats.leadershipGender.data} />
                                        :
                                        <div className="cs-shared-school-text">
                                            <h4 className="mbsc-txt-muted cs-shared-school-text-p">
                                                {locale.sharedSchool.b}
                                            </h4>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="mbsc-row mbsc-justify-content-between cs-shared-school-card-section">
                            <div className="mbsc-col-6 cs-shared-school-card-section-left">
                                <div className="cs-shared-school-card mbsc-padding">
                                    {school.stats.facultyGender.data.labels.length !== 0 ?
                                        <Doughnut options={school.stats.facultyGender.options} data={school.stats.facultyGender.data} />
                                        :
                                        <div className="cs-shared-school-text">
                                            <h4 className="mbsc-txt-muted cs-shared-school-text-p">
                                                {locale.sharedSchool.d}
                                            </h4>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="mbsc-col-6 cs-shared-school-card-section-right">
                                <div className="cs-shared-school-card mbsc-padding">
                                    {school.stats.facultyEthnicity.data.labels.length !== 0 ?
                                        <Bar options={school.stats.facultyEthnicity.options} data={school.stats.facultyEthnicity.data} />
                                        :
                                        <div className="cs-shared-school-text">
                                            <h4 className="mbsc-txt-muted cs-shared-school-text-p">
                                                {locale.sharedSchool.c}
                                            </h4>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {navigation.item === 'about' &&
                <div className="cs-shared-school-col-ck mbsc-padding">
                    <CKEditor id="editor-focus" editor={ClassicEditor} config={{toolbar: []}} disabled={true} data={school.about} />
                </div>
            }
            {navigation.item === 'jobs' &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    itemType={Item}
                    data={schoolData.jobs}
                    onItemTap={event => Job(event.index)}
                />
            }
            {navigation.item === 'gallery' &&
                <div className="mbsc-row cs-shared-school-gallery">
                    <div className="mbsc-col">
                        {school.gallery.col1.length !== 0 && school.gallery.col1.map((item) => {
                            return (
                                <div className="cs-shared-school-card-gallery" onClick={(ev) => {Image(item)}}>
                                    <img src={item.link} alt={item.link} />
                                </div>
                            );
                        })}
                    </div>
                    <div className="mbsc-col">
                        {school.gallery.col2.length !== 0 && school.gallery.col2.map((item) => {
                            return (
                                <div className="cs-shared-school-card-gallery" onClick={(ev) => {Image(item)}}>
                                    <img src={item.link} alt={item.link} />
                                </div>
                            );
                        })}
                    </div>
                    <div className="mbsc-col">
                        {school.gallery.col3.length !== 0 && school.gallery.col3.map((item) => {
                            return (
                                <div className="cs-shared-school-card-gallery" onClick={(ev) => {Image(item)}}>
                                    <img src={item.link} alt={item.link} />
                                </div>
                            );
                        })}
                    </div>
                    <div className="mbsc-col">
                        {school.gallery.col4.length !== 0 && school.gallery.col4.map((item) => {
                            return (
                                <div className="cs-shared-school-card-gallery" onClick={(ev) => {Image(item)}}>
                                    <img src={item.link} alt={item.link} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
            {(navigation.item === 'school' || navigation.item === 'about') &&
                <div className="mbsc-row cs-shared-school-footer">
                    <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Notes('favorite')} >
                        <FontAwesomeIcon className="cs-shared-school-button-icon" icon={favorite === false ? faHeartCirclePlus : faHeartCircleMinus} /> {favorite === false ? locale.sharedSchool.i : locale.sharedSchool.j}
                    </Button>
                </div>
            }
            {dialog.view === "schoolImage" &&
                <SchoolImage
                    Dialog={Dialog}
                    dialog={dialog}
                    image={image}
                />
            }
        </>
    );
}

export default School;
