import React from 'react';
import {Card, CardHeader, CardTitle, CardSubtitle, CardContent} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import DayJS from 'react-dayjs';
import {Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title} from 'chart.js';
import {Doughnut, Bar} from 'react-chartjs-2';
import '../styles/partnersPartner.css';

function PartnersPartner({locale, partner}) {

    ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

    return (
        <Card collapsible className="cs-partners-partner-card">
            {partner.cover &&
                <div className="cs-partners-partner-cover" style={{backgroundImage: `url(${partner.cover})`}} />
            }
            <CardHeader className="cs-partners-partner-card">
                <CardTitle>
                    {partner.name}
                </CardTitle>
                <CardSubtitle>
                    {partner.city && partner.city}{partner.country && ' | ' + partner.country}
                </CardSubtitle>
            </CardHeader>
            <CardContent className="cs-partners-partner-card">
                <div className="mbsc-grid">
                    <div className="mbsc-row mbsc-justify-content-center">
                        {partner.students &&
                            <div className="mbsc-col-12 mbsc-col-sm-6 mbsc-col-md-6 mbsc-col-lg-6 mbsc-col-xl-4">
                                <Card theme="ios" themeVariant="light">
                                    <CardHeader>
                                        <CardTitle className="cs-partners-partner-text"><p className="mbsc-txt-s mbsc-txt-muted cs-partners-partner-text-p">Number of students</p></CardTitle>
                                        <CardTitle className="cs-partners-partner-text"><p className="mbsc-txt-s cs-partners-partner-text-p">{partner.students}</p></CardTitle>
                                    </CardHeader>
                                </Card>
                            </div>
                        }
                        {partner.founded &&
                            <div className="mbsc-col-12 mbsc-col-sm-6 mbsc-col-md-6 mbsc-col-lg-6 mbsc-col-xl-4">
                                <Card theme="ios" themeVariant="light">
                                    <CardHeader>
                                        <CardTitle className="cs-partners-partner-text"><p className="mbsc-txt-s mbsc-txt-muted cs-partners-partner-text-p">Year founded</p></CardTitle>
                                        <CardTitle className="cs-partners-partner-text"><p className="mbsc-txt-s cs-partners-partner-text-p"><DayJS format="YYYY">{partner.founded}</DayJS></p></CardTitle>
                                    </CardHeader>
                                </Card>
                            </div>
                        }
                        <div className="mbsc-col-12 mbsc-col-sm-6 mbsc-col-md-6 mbsc-col-lg-6 mbsc-col-xl-4">
                            <Card theme="ios" themeVariant="light">
                                <CardHeader>
                                    <CardTitle className="cs-partners-partner-text"><p className="mbsc-txt-s mbsc-txt-muted cs-partners-partner-text-p">LGBTQIA+ friendly</p></CardTitle>
                                    <CardTitle className="cs-partners-partner-text"><p className="mbsc-txt-s cs-partners-partner-text-p">{partner.lgbtqia ? 'Yes' : 'No'}</p></CardTitle>
                                </CardHeader>
                            </Card>
                        </div>
                    </div>
                </div>
                <div className="mbsc-grid">
                    <div className="mbsc-row mbsc-justify-content-center">
                        <div className="mbsc-col-12">
                            <Card theme="ios" themeVariant="light">
                                <div className="mbsc-padding">
                                    {partner.stats.leadershipEthnicity.data.labels.length !== 0 ?
                                        <Bar options={partner.stats.leadershipEthnicity.options} data={partner.stats.leadershipEthnicity.data} />
                                        :
                                        <CardTitle className="cs-partners-partner-text"><p className="mbsc-txt-s mbsc-txt-muted cs-partners-partner-text-p">{locale.partnersPartner.a}</p></CardTitle>
                                    }
                                </div>
                            </Card>
                            <Card theme="ios" themeVariant="light">
                                <div className="mbsc-padding">
                                    {partner.stats.leadershipGender.data.labels.length !== 0 ?
                                        <Doughnut options={partner.stats.leadershipGender.options} data={partner.stats.leadershipGender.data} />
                                        :
                                        <CardTitle className="cs-partners-partner-text"><p className="mbsc-txt-s mbsc-txt-muted cs-partners-partner-text-p">{locale.partnersPartner.b}</p></CardTitle>
                                    }
                                </div>
                            </Card>
                            <Card theme="ios" themeVariant="light">
                                <div className="mbsc-padding">
                                    {partner.stats.facultyGender.data.labels.length !== 0 ?
                                        <Doughnut options={partner.stats.facultyGender.options} data={partner.stats.facultyGender.data} />
                                        :
                                        <CardTitle className="cs-partners-partner-text"><p className="mbsc-txt-s mbsc-txt-muted cs-partners-partner-text-p">{locale.partnersPartner.d}</p></CardTitle>
                                    }
                                </div>
                            </Card>
                            <Card theme="ios" themeVariant="light">
                                <div className="mbsc-padding">
                                    {partner.stats.facultyEthnicity.data.labels.length !== 0 ?
                                        <Bar options={partner.stats.facultyEthnicity.options} data={partner.stats.facultyEthnicity.data} />
                                        :
                                        <CardTitle className="cs-partners-partner-text"><p className="mbsc-txt-s mbsc-txt-muted cs-partners-partner-text-p">{locale.partnersPartner.c}</p></CardTitle>
                                    }
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}

export default PartnersPartner;
