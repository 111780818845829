import React, {useCallback, useState, useContext} from 'react';
import {Popup, Button, toast, Input, Select} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/schoolFacultyGender.css';
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGrid2Plus, faGenderless, faMars, faTrashCan, faVenus} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

function SchoolFacultyGender({Dialog, dialog, locale, account}) {
    const {wsCall} = useContext(NetworkContext);
    const [facultyGender, setFacultyGender] = useState(account.stats.facultyGender);
    const [gender, setGender] = useState('nonBinary');
    const [count, setCount] = useState('');

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Delete = data => {
        const item = {...facultyGender};
        let array = item.array;
        array = array.filter(function(item){
            return item.gender !== data.gender;
        })
        item.array = array;
        setFacultyGender(item);
        Save(array);
    }

    const Item = data => {
        return <li>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-school-faculty-gender-item-name">
                        {data.item.gender === 'male' &&
                            <FontAwesomeIcon className="cs-school-faculty-gender-item-icon-left" icon={faMars} />
                        }
                        {data.item.gender === 'female' &&
                            <FontAwesomeIcon className="cs-school-faculty-gender-item-icon-left" icon={faVenus} />
                        }
                        {data.item.gender === 'nonBinary' &&
                            <FontAwesomeIcon className="cs-school-faculty-gender-item-icon-left" icon={faGenderless} />
                        }
                        <div className="mbsc-bold mbsc-txt-muted cs-school-faculty-gender-item-text">
                            <span>{data.item.gender === 'male' && locale.schoolFacultyGender.b}{data.item.gender === 'female' && locale.schoolFacultyGender.c}{data.item.gender === 'nonBinary' && locale.schoolFacultyGender.d} ({data.item.count})</span>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-school-faculty-gender-item-buttons">
                    <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Delete(data.item)} >
                        <FontAwesomeIcon className="cs-school-faculty-gender-icon" icon={faTrashCan} />
                    </Button>
                </div>
            </div>
        </li>;
    }

    const Save = data => {
        const item = {
            leadershipEthnicity: account.stats.leadershipEthnicity.array,
            leadershipGender: account.stats.leadershipGender.array,
            facultyEthnicity: account.stats.facultyEthnicity.array,
            facultyGender: data
        };

        const message = {
            type: 'wss',
            path: 'account',
            action: 'update',
            data: {
                id: account.id,
                stats: item
            }
        };
        wsCall(message);
    }

    const Stats = () => {
        if (!count || !gender) {
            toast({message: locale.schoolFacultyGender.e, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const item = {...facultyGender};
            let array = item.array;
            if (array.length !== 0) {
                array = array.filter(function(item){
                    return item.gender !== gender;
                })
            }
            array.push({'gender': gender, 'count': count});
            item.array = array;
            setFacultyGender(item);
            setCount('');
            Save(array);
        }
    }

    return (
        <Popup className="cs-school-faculty-gender-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} theme="ios" themeVariant="light" scrollLock={false} display="center" headerText={locale.schoolFacultyGender.h} buttons={[{text: locale.schoolFacultyGender.i, cssClass: 'cs-school-faculty-gender-cancel', handler: () => CloseDialog()}]} isOpen={dialog.view === 'schoolFacultyGender'} onClose={CloseDialog}>
            <Select
                theme="ios"
                themeVariant="light"
                animation="slide-down"
                dropdown={false}
                inputStyle="underline"
                label={locale.schoolFacultyGender.a}
                labelStyle="floating"
                rows={locale.schoolFacultyGender.l.length}
                display="anchored"
                touchUi={false}
                value={gender}
                data={locale.schoolFacultyGender.l}
                onChange={(event) => setGender(event.value)}
            />
            <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.schoolFacultyGender.f} labelStyle="floating" type="number" name="count" value={count} onChange={ev => setCount(ev.target.value)} />
            <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold cs-school-faculty-gender-button-add" onClick={() => Stats()} >
                <FontAwesomeIcon className="cs-school-faculty-gender-icon" icon={faGrid2Plus} /> {locale.schoolFacultyGender.k}
            </Button>
            <div className="cs-school-faculty-gender-helper">
                <p className="mbsc-txt-muted">
                    <span className="mbsc-bold">{locale.schoolFacultyGender.g}</span>
                </p>
            </div>
            {!Empty(facultyGender) && facultyGender.array.length !== 0 &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    sortable={true}
                    itemType={Item}
                    data={facultyGender.array}
                />
            }
        </Popup>
    );
}

export default SchoolFacultyGender;
