import React, {useState, useContext, useCallback, useEffect} from 'react';
import {toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/schoolNotes.css';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

function SchoolNotes({locale, school, schoolData}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [text, setText] = useState(schoolData.notes.text ? schoolData.notes.text : '');
    const [notes, setNotes] = useState(schoolData.notes);
    const [draft, setDraft] = useState(true);

    const Change = data => {
        setText(data);

        if (data && data.length % 10 === 0) {
            const item = {...notes};
            item.text = data;
            setNotes(item);

            const message = {
                type: 'wss',
                path: 'account-notes',
                action: 'put',
                data: {
                    private: item,
                    shared: schoolData.notesShared,
                    country: school.country,
                    state: school.state,
                    logo: school.logo.link,
                    name: school.name,
                    city: school.city,
                    account: school.id
                }
            };
            wsCall(message);
        }
    }

    const Blur = () => {
        const item = {...notes};
        item.text = text;
        setNotes(item);

        const message = {
            type: 'wss',
            path: 'account-notes',
            action: 'put',
            data: {
                private: item,
                shared: schoolData.notesShared,
                country: school.country,
                state: school.state,
                logo: school.logo.link,
                name: school.name,
                city: school.city,
                account: school.id
            }
        };
        wsCall(message);
    }

    const PutAccountNotes = useCallback(data => {
        if (data.toast && !draft) {
            setDraft(true);
            toast({message: locale.schoolNotes.c, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.schoolNotes.c, draft]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'account-notes') {
            PutAccountNotes(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutAccountNotes, setWsResponse]);

    return (
        <>
            <div className="mbsc-row cs-school-notes-main">
                {!Empty(school.logo) ?
                    <div style={{backgroundImage: "url(" + school.logo.link + ")"}} className="cs-school-notes-avatar" />
                    :
                    <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=identicon')"}} className="cs-school-notes-avatar" />
                }
                <div>
                    <div>
                        <h4 className="cs-school-notes-header-title">{school.name}</h4>
                    </div>
                    <div>
                        {school.country &&
                            <span dangerouslySetInnerHTML={{__html: school.country}} />
                        }
                        {school.city &&
                            <span> | </span>
                        }
                        {school.city &&
                            <span dangerouslySetInnerHTML={{__html: school.city}} />
                        }
                        {school.state &&
                            <span> | </span>
                        }
                        {school.state &&
                            <span dangerouslySetInnerHTML={{__html: school.state}} />
                        }
                    </div>
                </div>
            </div>
            <div className="cs-school-notes-col-ck">
                <CKEditor
                    id="editor-focus"
                    config={{toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'undo', 'redo']}}
                    editor={ClassicEditor}
                    data={text}
                    onChange={(event, editor) => Change(editor.getData())}
                    onBlur={() => {Blur(); setDraft(false);}}
                />
            </div>
            <div className="mbsc-row cs-school-notes-footer">
                <div className="mbsc-col cs-school-notes-footer-actions" >
                    <div className="mbsc-txt-muted mbsc-italic">
                        {locale.schoolNotes.b}
                    </div>
                </div>
                <div className="mbsc-col-auto cs-school-notes-footer-note" />
            </div>
        </>
    );
}

export default SchoolNotes;
