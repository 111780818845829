import React, {useCallback} from 'react';
import {Popup} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/schoolImage.css';

function SchoolImage({Dialog, dialog, image}) {

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    return (
        <Popup className="cs-school-image-popup" theme="ios" themeVariant="light" scrollLock={false} display="center" buttons={[]} isOpen={dialog.view === 'schoolImage'} onClose={CloseDialog}>
            <div className="cs-school-image-popup-card">
                <img src={image}  alt={image} />
            </div>
        </Popup>
    );
}

export default SchoolImage;
