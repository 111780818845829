import React, {useCallback, useState, useContext} from 'react';
import {Input, Popup, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/candidatesFavoritesTags.css';
import {NetworkContext} from "../utils/NetworkContext";

function CandidatesFavoritesTags({locale, Dialog, dialog, favorites, setFavorites, favorite}) {
    const {wsCall} = useContext(NetworkContext);
    const [tag, setTag] = useState('');

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Save = () => {
        if (!tag) {
            toast({message: locale.candidatesFavoritesTags.d, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            let array = favorites;
            let tags = [];
            array = array.map(function(item){
                if (item.user === favorite){
                    item.tags.push(tag);
                    tags = item.tags;
                }
                return item;
            })
            setFavorites(array);

            const message = {
                type: 'wss',
                path: 'user-tags',
                action: 'update',
                data: {
                    candidate: favorite,
                    tags: tags
                }
            };
            wsCall(message);
            CloseDialog();
        }
    }

    return (
        <Popup className="cs-candidates-favorites-tags-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} theme="ios" themeVariant="light" display="center" headerText={locale.candidatesFavoritesTags.a} buttons={[{text: locale.candidatesFavoritesTags.f, cssClass: 'cs-candidates-favorites-tags-save', handler: 'cancel'}, {text: locale.candidatesFavoritesTags.b, cssClass: 'cs-candidates-favorites-tags-save', handler: ()=> Save()}]} isOpen={dialog.view === 'candidatesFavoritesTags'} onClose={CloseDialog}>
            <div className="cs-candidates-favorites-tags-helper">
                <p className="mbsc-txt-muted">
                    {locale.candidatesFavoritesTags.e}
                </p>
            </div>
            <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.candidatesFavoritesTags.c} labelStyle="floating" type="text" name="tag" value={tag} onChange={ev => setTag(ev.target.value)} />
        </Popup>
    );
}

export default CandidatesFavoritesTags;
