import React, {useCallback, useContext, useEffect} from 'react';
import {Button, confirm, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/userReferrals.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandshake, faPaperPlane, faSealExclamation, faTrashCan} from "@fortawesome/pro-duotone-svg-icons";
import DayJS from "react-dayjs";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

function UserReferrals({locale, width, user, setUser, Dialog}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);

    const Complete = () => {
        if (!user.referrals || !Array.isArray(user.referrals)) return false;

        const arrayTimestamp = user.referrals.filter(item =>
            item.hasOwnProperty('timestamp') && item.timestamp != null
        );

        return arrayTimestamp.length >= 1;
    }

    const Delete = data => {
        confirm({
            title: locale.userReferrals.a,
            message: locale.userReferrals.b,
            okText: locale.userReferrals.c,
            cancelText: locale.userReferrals.d,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'user-referral',
                        action: 'delete',
                        data: data
                    };
                    wsCall(message);
                }
            }
        });
    }

    const Item = data => {
        return <li>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-user-referrals-name">
                        <FontAwesomeIcon className="cs-user-referrals-icon-referral" icon={faPaperPlane} />
                        <div>
                            <div className="mbsc-bold">
                                <span>{data.item.firstName && data.item.firstName} {data.item.lastName && data.item.lastName}</span>
                            </div>
                            <div className="mbsc-txt-s mbsc-txt-muted cs-user-referrals-detail">
                                {data.item.email}{data.item.timestamp && width > 992 && ' | Completed: '}{!data.item.timestamp && width > 992 && ' | Requested: '}{!data.item.timestamp && width > 992 && <DayJS format="MMMM D, YYYY h:mm A">{data.item.request}</DayJS>}{data.item.timestamp && width > 992 && <DayJS format="MMMM D, YYYY h:mm A">{data.item.timestamp}</DayJS>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-user-referrals-delete">
                    <Button color="danger" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Delete(data.item)} >
                        {width > 992 && locale.userReferrals.f} <FontAwesomeIcon className="cs-user-referrals-icon-upload" icon={faTrashCan} />
                    </Button>
                </div>
            </div>
        </li>;
    }

    const DeleteUserReferral = useCallback(data => {
        if (data.toast) {
            toast({message: locale.userReferrals.e, color: 'info', duration: 1000, display: 'bottom'});
        }
        if (data.referrals) {
            const item = {...user};
            item.referrals = data.referrals;
            setUser(item);
        }
    }, [locale.userReferrals.e, user]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'user-referral') {
            DeleteUserReferral(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteUserReferral, setWsResponse]);

    return (
        <>
            <div className="mbsc-row cs-user-referrals-main">
                <div className="mbsc-col">
                    {!Complete() &&
                        <div className="cs-user-referrals-helper mbsc-row">
                            <FontAwesomeIcon className="cs-user-referrals-badge-incomplete" icon={faSealExclamation}/> <p className="mbsc-bold mbsc-txt-muted">{locale.userReferrals.k}</p>&nbsp;<p className="mbsc-txt-muted">{locale.userReferrals.i}</p>
                        </div>
                    }
                    {user.referrals && user.referrals.length !== 0 &&
                        <div>
                            <h4 className="cs-user-referrals-header-title mbsc-txt-muted">{locale.userReferrals.j}</h4>
                        </div>
                    }
                </div>
            </div>
            {user.referrals && user.referrals.length !== 0 &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    itemType={Item}
                    data={user.referrals}
                />
            }
            <div className="mbsc-row cs-user-referrals-footer">
                <div className="mbsc-col cs-user-referrals-footer-actions">
                    <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Dialog({view: 'userReferral'})} >
                        {locale.userReferrals.h} <FontAwesomeIcon className="cs-user-referrals-icon-upload" icon={faHandshake} />
                    </Button>
                </div>
                <div className="mbsc-col-auto cs-user-referrals-footer-note" />
            </div>
        </>
    );
}

export default UserReferrals;
