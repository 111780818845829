import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Input, Popup, toast, alert} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import Empty from '../utils/Empty';
import DayJS from "react-dayjs";
import '../styles/loginRenew.css';
import {NetworkContext} from "../utils/NetworkContext";

function LoginRenew({locale, Dialog, dialog, renew, setRenew}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [step, setStep] = useState(0);
    const [amount, setAmount] = useState(550);
    const [ccName, setCcName] = useState('');
    const [ccNumber, setCcNumber] = useState('');
    const [ccExpiration, setCcExpiration] = useState('');
    const [ccCvc, setCcCvc] = useState('');
    const [coupon, setCoupon] = useState('');
    const [processing, setProcessing] = useState(false);

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Buttons = () => {
        if (step === 0) {

            return [{
                text: locale.loginRenew.r,
                cssClass: 'cs-login-renew-cancel',
                handler: () => CloseDialog()
            }, {
                text: locale.loginRenew.t,
                cssClass: 'cs-login-renew-save',
                handler: () => CouponCheck()
            }]
        } else if (step === 1) {

            return [{
                text: locale.loginRenew.u,
                cssClass: 'cs-login-renew-cancel',
                handler: () => Back()
            }, {
                text: locale.loginRenew.o,
                cssClass: 'cs-login-renew-save',
                handler: () => Renew()
            }]
        }
    }

    const Back = () => {
        if (step !== 0) {
            let item= step - 1;
            setStep(item);
        }
    }

    const CouponCheck = () => {

        const string = ccNumber.replaceAll(' ', '');

        if (!ccName) {

            toast({ message: locale.loginRenew.f, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (string.length !== 16) {

            toast({ message: locale.loginRenew.e, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (string && isNaN(string)) {

            toast({ message: locale.loginRenew.e, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (!ccExpiration) {

            toast({ message: locale.loginRenew.g, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (ccExpiration.length !== 5) {

            toast({ message: locale.loginRenew.g, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (!ccCvc) {

            toast({ message: locale.loginRenew.g, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (ccCvc && isNaN(ccCvc)) {

            toast({ message: locale.loginRenew.g, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (coupon) {

            const message = {
                type: 'rest',
                path: 'coupon',
                action: 'get',
                data: {
                    coupon: coupon
                }
            };
            restCall(message);
        } else {
            let item= step + 1;
            setStep(item);
        }

    }

    const Renew = () => {

        const string = ccNumber.replaceAll(' ', '');

        if (processing) {

        } else {

            toast({message: locale.loginRenew.k, color: 'info', duration: 1000, display: 'bottom'});
            setProcessing(true);

            const message = {
                type: 'rest',
                path: 'account-stripe',
                action: 'update',
                data: {
                    number: string,
                    name: ccName,
                    expiration: ccExpiration,
                    cvc: ccCvc,
                    email: renew.email,
                    account: renew.account,
                    coupon: coupon
                }
            };
            restCall(message);
        }
    }

    const CC = data => {
        const string = data.replaceAll(' ', '');

        if (string.length <= 4) {
            setCcNumber(string);
        } else if (string.length > 4 && string.length <= 8) {
            const item = string.slice(0, 4) + ' ' + string.slice(4);
            setCcNumber(item);
        } else if (string.length > 8 && string.length <= 12) {
            const item = string.slice(0, 4) + ' ' + string.slice(4, 8) + ' ' + string.slice(8, 12) ;
            setCcNumber(item);
        } else if (string.length > 12 && string.length <= 16) {
            const item = string.slice(0, 4) + ' ' + string.slice(4, 8) + ' ' + string.slice(8, 12) + ' ' + string.slice(12, 16) ;
            setCcNumber(item);
        }
    }

    const Expiration = data => {
        const string = data.replaceAll('/', '');

        if (string.length < 2) {
            setCcExpiration(string);
        } else if (string.length === 2) {
            setCcExpiration(string + '/');
        } else if (string.length > 2 && string.length <= 4) {
            const item = string.slice(0, 2) + '/' + string.slice(2);
            setCcExpiration(item);
        }
    }

    const CVC = data => {
        if (data.length <= 4) {
            setCcCvc(data);
        }
    }

    const Next = useCallback(() => {
        let item= step + 1;
        setStep(item);
    }, [step]);

    const GetCoupon = useCallback(data => {
        if (data.next) {
            Next();
        }
        if (data.amount) {
            setAmount(data.amount);
        }
        if (data.error) {
            toast({message: locale.loginRenew.af, color: 'danger', duration: 3000, display: 'bottom'});
            setCoupon('');
        }
    }, [Next, locale.loginRenew.af]);

    const UpdateAccountStripe = useCallback(data => {
        setProcessing(false);
        if (data.stripe) {

            let message = locale.loginRenew.p;

            if (data.warning) {
                message = locale.loginRenew.n
            }

            alert({
                title: locale.loginRenew.i,
                message: message,
                okText: locale.loginRenew.j,
                callback: () => {
                    CloseDialog();
                }
            });
            setRenew({});
        }
        if (data.error && data.coupon) {

            toast({message: locale.loginRenew.ah, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (data.error && !data.coupon) {

            toast({message: locale.loginRenew.q, color: 'danger', duration: 3000, display: 'bottom'});
        }
    }, [locale.loginRenew.ah, locale.loginRenew.i, locale.loginRenew.p, locale.loginRenew.j, locale.loginRenew.q, CloseDialog]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'update' && restResponse.path === 'account-stripe') {
            UpdateAccountStripe(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, UpdateAccountStripe, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'coupon') {
            GetCoupon(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetCoupon, setRestResponse]);

    return (
        <Popup className="cs-login-renew-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} maxHeight={800} scrollLock={false} theme="ios" themeVariant="light" display="center" headerText={renew.name} buttons={Buttons()} isOpen={dialog.view === 'loginRenew'} onClose={CloseDialog}>
            {step === 0 &&
                <>
                    <div className="cs-login-renew-helper">
                        <p className="mbsc-txt-muted">
                            {locale.loginRenew.l}
                        </p>
                    </div>
                    <div className="cs-login-renew-helper">
                        <p className="mbsc-txt-muted">
                            {locale.loginRenew.m}
                        </p>
                    </div>
                    <div className="cs-login-renew-helper">
                        <h5 className="mbsc-txt-muted cs-login-renew-group-title-text">
                            {locale.loginRenew.h}
                        </h5>
                    </div>
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRenew.a} placeholder={locale.loginRenew.ab} labelStyle="floating" type="text" name="ccName" value={ccName} onChange={ev => setCcName(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRenew.b} placeholder={locale.loginRenew.ac} labelStyle="floating" type="text" name="ccNumber" value={ccNumber} onChange={ev => CC(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRenew.c} placeholder={locale.loginRenew.ae} labelStyle="floating" type="text" name="ccExpiration" value={ccExpiration} onChange={ev => Expiration(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRenew.d} placeholder={locale.loginRenew.ad} labelStyle="floating" type="text" name="ccCvc" value={ccCvc} onChange={ev => CVC(ev.target.value)} />
                    <div className="cs-login-renew-helper">
                        <p className="mbsc-txt-muted">
                            {locale.loginRenew.v}
                        </p>
                    </div>
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.loginRenew.s} labelStyle="floating" type="text" name="coupon" value={coupon} onChange={ev => setCoupon(ev.target.value)} />
                </>
            }
            {step === 1 &&
                <>
                    <div className="cs-login-renew-helper">
                        <p className="mbsc-txt-muted mbsc-bold">
                            {coupon ? locale.loginRenew.w : locale.loginRenew.ag}{amount.toFixed(2)} {locale.loginRenew.x}.
                        </p>
                    </div>
                    <div className="cs-login-renew-helper">
                        <p className="mbsc-txt-muted">
                            {locale.loginRenew.y}
                        </p>
                    </div>
                    <div className="cs-login-renew-helper">
                        <p className="mbsc-txt-muted">
                            {locale.loginRenew.aa} <a href="https://www.diversityfair.org/tcpp" target="_blank">{locale.loginRenew.z}</a>
                        </p>
                    </div>
                </>
            }
        </Popup>
    );
}

export default LoginRenew;
