import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Button, Checkbox, Input, Select, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/userInformation.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faKey, faSealExclamation} from "@fortawesome/pro-duotone-svg-icons";
import DayJS from "react-dayjs";
import Mobile from '../utils/Mobile';
import {NetworkContext} from "../utils/NetworkContext";

function UserInformation({locale, configuration, width, user, Dialog, email}) {
    const {wsCall} = useContext(NetworkContext);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [gender, setGender] = useState(user.gender);
    const [ethnicity, setEthnicity] = useState(user.ethnicity);
    const [lgbtqia, setLgbtqia] = useState(user.lgbtqia);
    const [mobile, setMobile] = useState(Mobile({data: user.mobile, setMobile: null}));
    const [publish, setPublish] = useState(user.publish);
    const [primaryCitizenship, setPrimaryCitizenship] = useState(user.primaryCitizenship);
    const [toSave, setToSave] = useState(false);

    const Complete = () => {
        let item = '';

        if (!publish) {

            item = item.concat(locale.userInformation.b);
        }
        if (!firstName) {

            item = item ? item.concat(', ' + locale.userInformation.j) : item.concat(locale.userInformation.j);
        }
        if (!lastName) {

            item = item ? item.concat(', ' + locale.userInformation.k) : item.concat(locale.userInformation.k);
        }
        if (!mobile) {

            item = item ? item.concat(', ' + locale.userInformation.l) : item.concat(locale.userInformation.l);
        }
        if (!gender) {

            item = item ? item.concat(', ' + locale.userInformation.q) : item.concat(locale.userInformation.q);
        }
        if (!ethnicity) {

            item = item ? item.concat(', ' + locale.userInformation.u) : item.concat(locale.userInformation.u);
        }
        if (!primaryCitizenship) {

            item = item ? item.concat(', ' + locale.userInformation.c) : item.concat(locale.userInformation.c);
        }
        return item;
    }

    const Save = useCallback(() => {
        if (!firstName || !lastName) {
            toast({message: locale.userInformation.g, color: 'danger', duration: 3000, display: 'bottom'});
        } else {

            let message = {};
            message.type = 'wss';
            message.path = 'user';
            message.action = 'update';
            message.data = {};
            message.data.email = email;
            message.data.firstName = firstName;
            message.data.lastName = lastName;
            message.data.mobile = mobile;
            if (user.candidate) {
                message.data.publish = publish;
                message.data.gender = gender;
                message.data.ethnicity = ethnicity;
                message.data.lgbtqia = lgbtqia;
                message.data.primaryCitizenship = primaryCitizenship;
            }
            wsCall(message);
        }
    }, [email, ethnicity, firstName, lastName, mobile, publish, gender, lgbtqia, primaryCitizenship, locale.userInformation.g, user.candidate]);

    useEffect(() => {
        if (toSave) {
            Save();
            setToSave(false);
        }
    }, [toSave, Save]);

    return (
        <>
            <div className="mbsc-row cs-user-information-main">
                <div className="mbsc-col">
                    {Complete() &&
                        <div className="cs-user-information-helper mbsc-row">
                            <FontAwesomeIcon className="cs-user-information-badge-incomplete" icon={faSealExclamation}/> <p className="mbsc-bold mbsc-txt-muted cs-user-information-error">{locale.userInformation.m}</p>&nbsp;<p className="mbsc-txt-muted cs-user-information-error">{Complete()}</p>
                        </div>
                    }
                    {user.candidate &&
                        <Checkbox
                            theme="ios"
                            themeVariant="light"
                            cssClass="cs-user-information-checkbox mbsc-bold"
                            color="info"
                            position="start"
                            label={publish ? locale.userInformation.e : locale.userInformation.b}
                            description={publish ? locale.userInformation.h : locale.userInformation.f}
                            checked={publish}
                            onChange={(ev) => {setPublish(ev.target.checked); setToSave(true);}}
                        />
                    }
                    <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.userInformation.j} labelStyle="floating" type="text" name="firstName" value={firstName} onBlur={Save} onChange={ev => setFirstName(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.userInformation.k} labelStyle="floating" type="text" name="lastName" value={lastName} onBlur={Save} onChange={ev => setLastName(ev.target.value)} />
                    <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.userInformation.i} labelStyle="floating" type="email" name="email" value={email} />
                    <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.userInformation.l} labelStyle="floating" type="text" name="mobile" value={mobile} onBlur={Save} onChange={ev => Mobile({data: ev.target.value, setMobile: setMobile})} />
                    {user.candidate &&
                        <Select
                            theme="ios"
                            themeVariant="light"
                            animation="slide-down"
                            dropdown={false}
                            inputStyle="outline"
                            label={locale.userInformation.q}
                            labelStyle="floating"
                            rows={locale.userInformation.p.length}
                            display="anchored"
                            touchUi={false}
                            value={gender}
                            data={locale.userInformation.p}
                            onChange={(event) => {setGender(event.value); setToSave(true);}}
                        />
                    }
                    {user.candidate &&
                        <Checkbox
                            theme="ios"
                            themeVariant="light"
                            cssClass="cs-user-information-checkbox mbsc-bold"
                            color="info"
                            position="start"
                            label={locale.userInformation.d}
                            checked={lgbtqia}
                            onChange={(ev) => {setLgbtqia(ev.target.checked); setToSave(true);}}
                        />
                    }
                    {user.candidate &&
                        <Select
                            theme="ios"
                            themeVariant="light"
                            animation="slide-down"
                            dropdown={false}
                            inputStyle="outline"
                            label={locale.userInformation.u}
                            labelStyle="floating"
                            rows={locale.userInformation.ab.length}
                            display="anchored"
                            touchUi={false}
                            value={ethnicity}
                            data={locale.userInformation.ab}
                            onChange={(event) => {setEthnicity(event.value); setToSave(true);}}
                        />
                    }
                    {user.candidate &&
                        <Select
                            theme="ios"
                            themeVariant="light"
                            animation="slide-down"
                            dropdown={false}
                            inputStyle="outline"
                            label={locale.userInformation.c}
                            labelStyle="floating"
                            rows={10}
                            display="anchored"
                            touchUi={false}
                            value={primaryCitizenship}
                            data={configuration.mobi.countries}
                            onChange={(event) => {setPrimaryCitizenship(event.value); setToSave(true);}}
                        />
                    }
                </div>
            </div>
            <div className="mbsc-row cs-user-information-footer">
                <div className="mbsc-col cs-user-information-footer-actions">
                    <div className="mbsc-row">
                        <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Dialog({view: 'userReset'})} >
                            <FontAwesomeIcon className="cs-user-information-icon-upload" icon={faKey} /> {locale.userInformation.n}
                        </Button>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-user-information-footer-note">
                    {user.lastLogin && width > 992 &&
                        <div className="mbsc-txt-muted mbsc-italic">{locale.userInformation.o} <DayJS locale={user.locale} format="MMMM D, YYYY h:mm A">{user.lastLogin}</DayJS></div>
                    }
                </div>
            </div>
        </>
    );
}

export default UserInformation;
