import React, {useContext, useRef, useState} from 'react';
import {Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/notes.css';
import NotesNote from "../dialogs/NotesNote";
import {NetworkContext} from "../utils/NetworkContext";

function Notes({locale, dialog, Dialog, user}) {
    const {wsCall} = useContext(NetworkContext);
    const start = useRef(0);
    const end = useRef(0);
    const [notes, setNotes] = useState(user.notes);
    const [note, setNote] = useState({});

    const Open = data => {
        setNote(notes[data]);
        Dialog({view: 'note'});
    }

    const Item = data => {
        return <li className="cs-notes-li" key={data.item.text}>
            <div className={`mbsc-note mbsc-note-${data.item.type}`}>
                {data.item.text}
            </div>
        </li>;
    }

    const New = () => {
        setNote({});
        Dialog({view: 'note'});
    }

    const Start = data => {
        start.current = data;
    }

    const End = data => {
        end.current = data;
        let array = notes;
        let array2 = [];
        array.splice(end.current, 0, array.splice(start.current, 1)[0]);
        array = array.map(function(item){
            array2.push({
                text: item.text,
                type: item.type
            })
            return item;
        })
        setNotes(array);

        const message = {
            type: 'wss',
            path: 'user',
            action: 'update',
            data: {
                email: user.email,
                notes: array2
            }
        };
        wsCall(message);
    }

    return (
        <>
            <div className="mbsc-button-group-block">
                <Button color="primary" variant="standard" theme="ios" themeVariant="light" title={locale.dashboardHeader.a} className="cs-notes-button mbsc-bold" onClick={() => New()} >
                    {locale.notes.a}
                </Button>
            </div>
            <Listview
                theme="ios"
                themeVariant="light"
                className="cs-notes-li"
                sortable={true} select="single"
                itemType={Item}
                data={notes}
                onSortStart={event => Start(event.index)}
                onSortEnd={event => End(event.index)}
                onItemTap={event => Open(event.index)}
            />
            {dialog.view === 'note' &&
                <NotesNote
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    note={note}
                    notes={notes}
                    setNotes={setNotes}
                    user={user}
                />
            }
        </>
    );
}

export default Notes;
