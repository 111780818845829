import React, {useEffect} from 'react';
import {SegmentedGroup, Segmented} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/user.css';
import Menu from "../navigation/Menu";
import Item from "../navigation/Item";
import Notes from "../shared/Notes";
import UserReset from "../dialogs/UserReset";
import UserReferral from "../dialogs/UserReferral";
import UserInformation from "../blocks/UserInformation";
import UserBio from "../blocks/UserBio";
import UserJob from "../blocks/UserJob";
import UserSpouse from "../blocks/UserSpouse";
import UserOptions from "../blocks/UserOptions";
import UserDocuments from "../blocks/UserDocuments";
import UserReferrals from "../blocks/UserReferrals";

function User({width, locale, navigation, Navigation, dialog, Dialog, configuration, user, setUser, setDraft}) {

    const Complete = data => {
        if (data === 'information') {

            return !!(user.publish && user.firstName && user.lastName && user.mobile && user.gender && user.ethnicity && user.primaryCitizenship);

        } else if (data === 'bio') {

            if (user.bio) {
                const text = user.bio.replace(/<[^>]+>/g, '');
                const trimmedText = text.trim();

                return trimmedText.length > 150;
            } else {
                return false;
            }

        } else if (data === 'job') {

            return !!((user.teacher || user.leader) && user.job && user.school && user.division);

        } else if (data === 'documents') {
            if (!user.documents || !Array.isArray(user.documents)) return false;

            if (user.teacher && !user.leader) {
                return user.documents.some(item => item.tag === "resume");
            } else if (user.leader) {

                const resume = user.documents.some(item => item.tag === "resume");
                const philosophy = user.documents.some(item => item.tag === "philosophy");

                return resume && philosophy;

            } else {
                return false;
            }

        } else if (data === 'referrals') {
            if (!user.referrals || !Array.isArray(user.referrals)) return false;

            const arrayTimestamp = user.referrals.filter(item =>
                item.hasOwnProperty('timestamp') && item.timestamp != null
            );

            return arrayTimestamp.length >= 1;

        } else if (data === 'options') {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (user.candidate) {
            Navigation({menu: locale.user.e});
        } else if (!user.candidate) {
            Navigation({menu: locale.user.f});
        }
    }, []);

  return (
      <div className="mbsc-grid cs-user-main">
          <div className={width > 768 ? "mbsc-row cs-user-container" : "mbsc-row cs-user-container-xs"}>
              <div className="mbsc-col cs-user-title">
                  <p className={width > 768 ? "mbsc-txt-l" : "mbsc-txt-m"}>
                      {user.candidate ? locale.user.a : locale.user.b}
                  </p>
              </div>
          </div>
          <div className="mbsc-row">
              {width > 768 &&
                  <div className="mbsc-col-12 mbsc-col-md-3 mbsc-col-sm-12 cs-user-left">
                      {navigation.menu && navigation.menu.length !== 0 &&
                          <Listview
                              theme="ios"
                              themeVariant="light"
                              select="single"
                              itemType={event => user.candidate ? Item(event, navigation, Complete) : Item(event, navigation, null)}
                              data={navigation.menu}
                              onItemTap={event => Menu(event.index, Navigation, navigation)}
                          />
                      }
                      <Notes
                          locale={locale}
                          dialog={dialog}
                          Dialog={Dialog}
                          user={user}
                      />
                  </div>
              }
              <div className="mbsc-col-12 mbsc-col-md-9 mbsc-col-sm-12 cs-user-right">
                  <div className="mbsc-row mbsc-justify-content-center cs-user-section">
                      <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + user.avatar + "?d=robohash)"}} className="cs-user-avatar" />
                  </div>
                  <div className="mbsc-padding mbsc-row mbsc-justify-content-center cs-user-section">
                      <a href="https://en.gravatar.com" target="_blank">
                          {locale.user.g}
                      </a>
                  </div>
                  {navigation.menu && navigation.menu.length !== 0 && width < 768 &&
                      <div className="mbsc-row cs-user-section">
                          <div className="mbsc-col">
                              <SegmentedGroup theme="ios" themeVariant="light" className="cs-user-nav" name="jobs" color="primary" value={navigation.tab} onChange={ev => Menu(ev, Navigation, navigation)}>
                                  {navigation.menu.map((item) => {
                                      return (
                                          <Segmented value={item.name} theme="ios" themeVariant="light" >
                                              {item.label}
                                          </Segmented>
                                      );
                                  })}
                              </SegmentedGroup>
                          </div>
                      </div>
                  }
                  {navigation.tab === "information" &&
                      <UserInformation
                          locale={locale}
                          configuration={configuration}
                          width={width}
                          user={user}
                          Dialog={Dialog}
                          email={user.email}
                      />
                  }
                  {navigation.tab === "bio" &&
                      <UserBio
                          locale={locale}
                          width={width}
                          user={user}
                          email={user.email}
                          setDraft={setDraft}
                      />
                  }
                  {navigation.tab === "job" &&
                      <UserJob
                          locale={locale}
                          width={width}
                          user={user}
                          email={user.email}
                      />
                  }
                  {navigation.tab === "spouse" &&
                      <UserSpouse
                          locale={locale}
                          configuration={configuration}
                          width={width}
                          user={user}
                          email={user.email}
                      />
                  }
                  {navigation.tab === "documents" &&
                      <UserDocuments
                          locale={locale}
                          width={width}
                          user={user}
                          email={user.email}
                          setUser={setUser}
                      />
                  }
                  {navigation.tab === "referrals" &&
                      <UserReferrals
                          locale={locale}
                          width={width}
                          user={user}
                          setUser={setUser}
                          Dialog={Dialog}
                      />
                  }
                  {navigation.tab === "options" &&
                      <UserOptions
                          locale={locale}
                          user={user}
                          email={user.email}
                      />
                  }
              </div>
          </div>
          {dialog.view === 'userReset' &&
              <UserReset
                  locale={locale}
                  Dialog={Dialog}
                  dialog={dialog}
                  user={user}
              />
          }
          {dialog.view === 'userReferral' &&
              <UserReferral
                  locale={locale}
                  Dialog={Dialog}
                  dialog={dialog}
                  user={user}
                  setUser={setUser}
              />
          }
      </div>
  );
}

export default User;
