const Mobile = ({data, setMobile}) => {
    let mobile = '';
    if (data) {
        const itemClean = data.replace(/[^\d]/g, '');
        let itemFormatted = '';
        if (itemClean.length <= 3) {
            itemFormatted = itemClean;
        } else if (itemClean.length <= 6) {
            itemFormatted = `(${itemClean.slice(0, 3)}) ${itemClean.slice(3)}`;
        } else {
            itemFormatted = `(${itemClean.slice(0, 3)}) ${itemClean.slice(3, 6)}-${itemClean.slice(6, 10)}`;
        }
        mobile = itemFormatted;
    }
    if (mobile && setMobile) {
        setMobile(mobile);
    } else if (mobile && !setMobile) {
        return mobile;
    }
};

export default Mobile;
