import React, {useState, useContext, useCallback, useEffect} from 'react';
import {Button, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/jobNotes.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFloppyDisk} from "@fortawesome/pro-duotone-svg-icons";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

function JobNotes({locale, job, jobData, navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [text, setText] = useState(jobData.notes.text ? jobData.notes.text : '');
    const [notes, setNotes] = useState(jobData.notes);

    const School = () => {
        const message = {
            type: 'wss',
            path: 'school',
            action: 'get',
            data: {
                id: job.account
            }
        };
        wsCall(message);
    }

    const Notes = data => {
        const item = {...notes};
        item.text = text;
        setNotes(item);

        const message = {
            type: 'wss',
            path: 'job-notes',
            action: 'put',
            data: {
                private: item,
                shared: jobData.notesShared,
                job: job.id,
                school: job.account,
                logo: job.logo,
                name: job.name,
                title: job.title
            }
        };
        wsCall(message);
    }

    const PutJobNotes = useCallback(data => {
        if (data.toast) {
            toast({message: locale.jobNotes.c, color: 'info', duration: 3000, display: 'bottom'});
        }
    }, [locale.jobNotes.c]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'job-notes') {
            PutJobNotes(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutJobNotes, setWsResponse]);

    return (
        <>
            <div className="mbsc-row cs-job-notes-main">
                {job.logo ?
                    <div style={{backgroundImage: "url(" + job.logo + ")"}} className="cs-job-notes-avatar" />
                    :
                    <div style={{backgroundImage: "url('https://www.gravatar.com/avatar/" + (+ new Date()) + "?d=identicon')"}} className="cs-job-notes-avatar" />
                }
                <div>
                    <div>
                        <h4 className="cs-job-notes-header-title">{job.title}</h4>
                    </div>
                    <div onClick={(ev) => navigation.page === 'jobs' && School()}>
                        <span className={navigation.page === 'jobs' ? 'cs-shared-job-links' : ''}>
                            {navigation.page === 'jobs' && job.name + ' | '}
                            {job.country && job.country}
                            {job.city && <span> | </span>}
                            {job.city && job.city}
                            {job.state && <span> | </span>}
                            {job.state && job.state}
                        </span>
                    </div>
                </div>
            </div>
            <div className="cs-job-notes-col-ck">
                <CKEditor id="editor-focus" config={{toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'undo', 'redo']}} editor={ClassicEditor} data={text} onChange={(event, editor) => setText(editor.getData())} />
            </div>
            <div className="mbsc-row cs-job-notes-footer">
                <div className="mbsc-col cs-jobs-search-footer-filter">
                    <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Notes()} >
                        <FontAwesomeIcon className="cs-job-notes-button-icon" icon={faFloppyDisk} /> {locale.jobNotes.a}
                    </Button>
                </div>
                <div className="mbsc-col-auto cs-jobs-search-footer-note">
                    <div className="mbsc-txt-muted mbsc-italic">
                        {locale.jobNotes.b}
                    </div>
                </div>
            </div>
        </>
    );
}

export default JobNotes;
