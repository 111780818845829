import React, {useState, useContext} from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/candidateNotes.css';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {NetworkContext} from "../utils/NetworkContext";

function CandidateNotes({locale, candidate, candidateData, setDraft}) {
    const {wsCall} = useContext(NetworkContext);
    const [text, setText] = useState(candidateData.notes.text);
    const [notes, setNotes] = useState(candidateData.notes);

    const Change = data => {
        setText(data);

        if (data && data.length % 10 === 0) {
            const item = {...notes};
            item.text = data;
            setNotes(item);

            const message = {
                type: 'wss',
                path: 'user-notes',
                action: 'put',
                data: {
                    private: item,
                    shared: candidateData.notesShared,
                    candidate: candidate.email,
                    avatar: candidate.avatar,
                    firstName: candidate.firstName,
                    lastName: candidate.lastName
                }
            };
            wsCall(message);
        }
    }

    const Blur = () => {
        const item = {...notes};
        item.text = text;
        setNotes(item);

        const message = {
            type: 'wss',
            path: 'user-notes',
            action: 'put',
            data: {
                private: item,
                shared: candidateData.notesShared,
                candidate: candidate.email,
                avatar: candidate.avatar,
                firstName: candidate.firstName,
                lastName: candidate.lastName
            }
        };
        wsCall(message);
    }

    return (
        <>
            <div className="mbsc-row cs-candidate-notes-main">
                <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + candidate.avatar + "?d=robohash)"}} className="cs-candidate-notes-avatar" />
                <div>
                    <div>
                        <h4 className="cs-candidate-notes-header-title">
                            {candidate.firstName} {candidate.lastName}
                        </h4>
                    </div>
                    <div>
                        {candidate.email && candidate.email}
                        {candidate.country && ' | '}
                        {candidate.country && candidate.country}
                    </div>
                </div>
            </div>
            <div className="cs-candidate-notes-col-ck">
                <CKEditor
                    id="editor-focus"
                    config={{toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'outdent', 'indent', '|', 'blockQuote', 'undo', 'redo']}}
                    editor={ClassicEditor}
                    data={text}
                    onChange={(event, editor) => Change(editor.getData())}
                    onBlur={() => {Blur(); setDraft(false);}}
                />
            </div>
            <div className="mbsc-row cs-candidate-notes-footer">
                <div className="mbsc-col cs-candidate-notes-footer-actions">
                    <div className="mbsc-txt-muted mbsc-italic">
                        {locale.candidateNotes.b}
                    </div>
                </div>
                <div className="mbsc-col-auto cs-candidate-notes-footer-note" />
            </div>
        </>
    );
}

export default CandidateNotes;
