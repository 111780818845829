import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {Button, Input, Select, toast, confirm} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/userDocuments.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudArrowDown, faFilePdf, faSealExclamation, faTrashCan} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

function UserDocuments({locale, width, user, email, setUser}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const hiddenFileInput = useRef(null);
    const [url, setUrl] = useState(user.url);
    const [urlText, setUrlText] = useState(user.urlText);
    const [tag, setTag] = useState('');

    const Complete = () => {
        if (!user.documents || !Array.isArray(user.documents)) return false;

        if (user.teacher && !user.leader) {

            return user.documents.some(item => item.tag === "resume");
        } else if (user.leader) {

            const resume = user.documents.some(item => item.tag === "resume");
            const philosophy = user.documents.some(item => item.tag === "philosophy");

            return resume && philosophy;

        } else {
            return false;
        }
    }

    const Save = () => {
        const message = {
            type: 'wss',
            path: 'user',
            action: 'update',
            data: {
                email: email,
                url: url,
                urlText: urlText
            }
        };
        wsCall(message);
    }

    const Tag = data => {
        let item = true;

        if (user.documents.length !== 0) {
            user.documents.some(itemDocument => {
                if (itemDocument.tag === data) {
                    item = false;
                    return true;
                }
                return false;
            });
        }
        if (!item) {
            toast({message: locale.userDocuments.h, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            setTag(data);
            hiddenFileInput.current.click()
        }
    }

    const Upload = data => {
        const name = data.target.files[0].name;
        let file = '';

        const reader = new FileReader();
        reader.addEventListener('load', ()=>{
            file = reader.result;

            const message = {
                type: 'wss',
                path: 'user',
                action: 'update',
                data: {
                    email: email,
                    fileName: name,
                    file: file,
                    fileTag: tag,
                    documents: user.documents
                }
            };
            wsCall(message);
        })
        reader.readAsDataURL(data.target.files[0]);
    }

    const Download = data => {
        const message = {
            type: 'wss',
            path: 'user-file',
            action: 'get',
            data: data
        };
        wsCall(message);
    }

    const Delete = data => {
        confirm({
            title: locale.userDocuments.r,
            message: locale.userDocuments.s,
            okText: locale.userDocuments.u,
            cancelText: locale.userDocuments.q,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'user-file',
                        action: 'delete',
                        data: {
                            item : data,
                            documents : user.documents
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const Document = data => {
        let item = '';
        locale.userDocuments.f.some(itemType => {
            if (itemType.value === data) {
                item = itemType.text;
                return true;
            }
            return false;
        });
        return item;
    }

    const DocumentItem = data => {
        return <li>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-user-documents-list">
                        <FontAwesomeIcon className="cs-user-documents-icon-referral" icon={faFilePdf} />
                        <div className="cs-user-documents-file">
                            <div className="mbsc-row">
                                <h4 className="cs-user-documents-file-text">{Document(data.item.tag)}</h4>
                            </div>
                            <div>
                                {data.item.fileName}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-user-documents-file-button">
                    <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Download(data.item)} >
                        {width > 992 && locale.userDocuments.t} <FontAwesomeIcon className="cs-user-documents-icon-upload" icon={faCloudArrowDown} />
                    </Button>
                    <Button color="danger" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Delete(data.item)} >
                        {width > 992 && locale.userDocuments.u} <FontAwesomeIcon className="cs-user-documents-icon-upload" icon={faTrashCan} />
                    </Button>
                </div>
            </div>
        </li>;
    }

    const DeleteUserFile = useCallback(data => {
        if (data.toast) {
            toast({message: locale.userDocuments.k, color: 'info', duration: 1000, display: 'bottom'});
        }
        if (data.documents) {
            const item = {...user};
            item.documents = data.documents;
            setUser(item);
        }
    }, [locale.userDocuments.k, user]);

    const GetUserFile = useCallback(data => {
        if (data.toast) {
            toast({message: locale.userDocuments.k, color: 'info', duration: 1000, display: 'bottom'});
        }
        if (data.url) {
            const a = document.createElement('a');
            a.href = data.url;
            a.click();
        }
    }, [locale.userDocuments.k]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'user-file') {
            DeleteUserFile(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteUserFile, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'user-file') {
            GetUserFile(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetUserFile, setWsResponse]);

    return (
        <>
            <div className="mbsc-row cs-user-documents-main">
                <div className="mbsc-col">
                    {!Complete() &&
                        <div className="cs-user-documents-helper mbsc-row">
                            <FontAwesomeIcon className="cs-user-documents-badge-incomplete" icon={faSealExclamation}/> <p className="mbsc-bold mbsc-txt-muted">{locale.userDocuments.c}</p>&nbsp;<p className="mbsc-txt-muted">{(user.teacher && !user.leader) ? locale.userDocuments.p : locale.userDocuments.w}</p>
                        </div>
                    }
                    <div>
                        <h4 className="cs-user-documents-header-title mbsc-txt-muted">{locale.userDocuments.l}</h4>
                    </div>
                    <Select
                        theme="ios"
                        themeVariant="light"
                        animation="slide-down"
                        dropdown={false}
                        inputStyle="outline"
                        label={locale.userDocuments.e}
                        labelStyle="floating"
                        rows={locale.userDocuments.f.length}
                        display="anchored"
                        touchUi={false}
                        value={tag}
                        data={locale.userDocuments.f}
                        onChange={(event) => Tag(event.value)}
                    />
                    <div>
                        <h4 className="cs-user-documents-header-title mbsc-txt-muted">{locale.userDocuments.a}</h4>
                    </div>
                    <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.userDocuments.j} labelStyle="floating" type="text" name="lastName" value={url} onBlur={Save} onChange={ev => setUrl(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="outline" label={locale.userDocuments.b} labelStyle="floating" type="text" name="lastName" value={urlText} onBlur={Save} onChange={ev => setUrlText(ev.target.value)} />
                    <div>
                        <h4 className="cs-user-documents-header-files mbsc-txt-muted">
                            {user.documents.length !== 0 &&
                                locale.userDocuments.d
                            }
                        </h4>
                    </div>
                </div>
            </div>
            {user.documents.length !== 0 &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    itemType={DocumentItem}
                    data={user.documents}
                />
            }
            <div style={{display: 'none'}}>
                <input type="file" ref={hiddenFileInput} onChange={Upload} />
            </div>
        </>
    );
}

export default UserDocuments;
