import React, {useEffect, useState} from 'react';
import {SegmentedGroup, Segmented} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/jobs.css';
import '../styles/ckeditor.css';
import Empty from "../utils/Empty";
import Menu from "../navigation/Menu";
import Item from "../navigation/Item";
import Notes from "../shared/Notes";
import Job from "../shared/Job";
import JobNotes from "../shared/JobNotes";
import School from "../shared/School";
import SchoolNotes from "../shared/SchoolNotes";
import JobsSearch from "../blocks/JobsSearch";
import JobsResults from "../blocks/JobsResults";
import JobsFavorites from "../blocks/JobsFavorites";
import JobsInterest from "../blocks/JobsInterest";

function Jobs({width, locale, navigation, Navigation, dialog, Dialog, user}) {
    const [text, setText] = useState('');
    const [filter, setFilter] = useState('');
    const [results, setResults] = useState([]);
    const [result, setResult] = useState({});
    const [job, setJob] = useState({});
    const [school, setSchool] = useState({});

    useEffect(() => {
        Navigation({menu: locale.jobs.c});
    }, []);

  return (
      <div className="mbsc-grid cs-jobs-main">
          <div className={width > 768 ? "mbsc-row cs-jobs-container" : "mbsc-row cs-jobs-container-xs"}>
              <div className="mbsc-col cs-jobs-title">
                  <p className={width > 768 ? "mbsc-txt-l" : "mbsc-txt-m"}>
                      {locale.jobs.b}
                  </p>
              </div>
          </div>
          <div className="mbsc-row">
              {width > 768 &&
                  <div className="mbsc-col-12 mbsc-col-md-3 mbsc-col-sm-12 cs-jobs-left">
                      {navigation.menu && navigation.menu.length !== 0 &&
                          <Listview
                              theme="ios"
                              themeVariant="light"
                              select="single"
                              itemType={event => Item(event, navigation, null)}
                              data={navigation.menu}
                              onItemTap={event => Menu(event.index, Navigation, navigation)}
                          />
                      }
                      <Notes
                          locale={locale}
                          dialog={dialog}
                          Dialog={Dialog}
                          user={user}
                      />
                  </div>
              }
              <div className="mbsc-col-12 mbsc-col-md-9 mbsc-col-sm-12 cs-jobs-right">
                  {navigation.menu && navigation.menu.length !== 0 && width < 768 &&
                      <div className="mbsc-row cs-jobs-section">
                          <div className="mbsc-col">
                              <SegmentedGroup theme="ios" themeVariant="light" className="cs-jobs-nav" name="jobs" color="primary" value={navigation.tab} onChange={ev => Menu(ev, Navigation, navigation)}>
                                  {navigation.menu.map((item) => {
                                      return (
                                          <Segmented value={item.name} theme="ios" themeVariant="light" >
                                              {item.label}
                                          </Segmented>
                                      );
                                  })}
                              </SegmentedGroup>
                          </div>
                      </div>
                  }
                  {navigation.tab === 'search' &&
                      <JobsSearch
                          locale={locale}
                          setResults={setResults}
                          user={user}
                          text={text}
                          setText={setText}
                          filter={filter}
                          setFilter={setFilter}
                          results={results}
                          Navigation={Navigation}
                      />
                  }
                  {results.length !== 0 && navigation.tab === 'search' &&
                      <JobsResults
                          results={results}
                          setResult={setResult}
                          setJob={setJob}
                          Navigation={Navigation}
                          width={width}
                      />
                  }
                  {!Empty(job) && navigation.tab === 'job' &&
                      <Job
                          locale={locale}
                          user={user}
                          job={job.job}
                          jobData={job}
                          setSchool={setSchool}
                          Navigation={Navigation}
                          navigation={navigation}
                      />
                  }
                  {!Empty(job) && navigation.tab === 'jobNotes' &&
                      <JobNotes
                          locale={locale}
                          job={job.job}
                          jobData={job}
                          navigation={navigation}
                      />
                  }
                  {!Empty(school) && navigation.tab === 'school' &&
                      <School
                          locale={locale}
                          Dialog={Dialog}
                          dialog={dialog}
                          school={school.school}
                          schoolData={school}
                          Navigation={Navigation}
                          navigation={navigation}
                          setJob={setJob}
                          width={width}
                      />
                  }
                  {!Empty(school) && navigation.tab === 'schoolNotes' &&
                      <SchoolNotes
                          locale={locale}
                          school={school.school}
                          schoolData={school}
                      />
                  }
                  {navigation.tab === 'favorites' &&
                      <JobsFavorites
                          locale={locale}
                          setJob={setJob}
                          Navigation={Navigation}
                          width={width}
                      />
                  }
                  {navigation.tab === 'interest' &&
                      <JobsInterest
                          locale={locale}
                          setJob={setJob}
                          Navigation={Navigation}
                          user={user}
                          width={width}
                      />
                  }
              </div>
          </div>
      </div>
  );
}

export default Jobs;
