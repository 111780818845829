import React, {useCallback, useContext, useEffect, useState} from 'react';
import {SegmentedGroup, Segmented, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/school.css';
import Empty from "../utils/Empty";
import Menu from "../navigation/Menu";
import Item from "../navigation/Item";
import Notes from "../shared/Notes";
import SchoolHeader from '../blocks/SchoolHeader';
import SchoolDiversity from "../blocks/SchoolDiversity";
import SchoolInformation from "../blocks/SchoolInformation";
import SchoolAbout from "../blocks/SchoolAbout";
import SchoolLocation from "../blocks/SchoolLocation";
import SchoolGallery from "../blocks/SchoolGallery";
import {NetworkContext} from "../utils/NetworkContext";

function School({width, locale, navigation, Navigation, dialog, Dialog, configuration, user, account, setAccount}) {
    const {wsResponse, setWsResponse} = useContext(NetworkContext);

    const GetSchool = useCallback(data => {
        if (data.school) {
            setAccount(data.school);
        }
    }, []);

    const UpdateAccount = useCallback(data => {
        if (data.school) {
            setAccount(data.school);
        }
        if (data.toast && !data.silent) {
            console.log(data);
            toast({message: locale.school.c, color: 'info', duration: 1000, display: 'bottom'});
        }
    }, [locale.school.c]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'account') {
            UpdateAccount(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateAccount, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'school') {
            GetSchool(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetSchool, setWsResponse]);

    useEffect(() => {
        Navigation({menu: locale.school.d});
    }, []);

  return (
      <div className="mbsc-grid cs-school-main">
          <div className={width > 768 ? "mbsc-row cs-school-container" : "mbsc-row cs-school-container-xs"}>
              <div className="mbsc-col cs-school-title">
                  <p className={width > 768 ? "mbsc-txt-l" : "mbsc-txt-m"}>
                      {locale.school.b}
                  </p>
              </div>
          </div>
          <div className="mbsc-row">
              {width > 768 &&
                  <div className="mbsc-col-12 mbsc-col-md-3 mbsc-col-sm-12 cs-school-left">
                      {navigation.menu && navigation.menu.length !== 0 &&
                          <Listview
                              theme="ios"
                              themeVariant="light"
                              select="single"
                              itemType={event => Item(event, navigation, null)}
                              data={navigation.menu}
                              onItemTap={event => Menu(event.index, Navigation, navigation)}
                          />
                      }
                      <Notes
                          locale={locale}
                          dialog={dialog}
                          Dialog={Dialog}
                          user={user}
                      />
                  </div>
              }
              <div className="mbsc-col-12 mbsc-col-md-9 mbsc-col-sm-12 cs-school-right">
                  <SchoolHeader
                      locale={locale}
                      account={account}
                  />
                  {navigation.menu && navigation.menu.length !== 0 && width < 768 &&
                      <div className="mbsc-row cs-school-section">
                          <div className="mbsc-col">
                              <SegmentedGroup theme="ios" themeVariant="light" className="cs-school-nav" name="jobs" color="primary" value={navigation.tab} onChange={ev => Menu(ev, Navigation, navigation)}>
                                  {navigation.menu.map((item) => {
                                      return (
                                          <Segmented value={item.name} theme="ios" themeVariant="light" >
                                              {item.label}
                                          </Segmented>
                                      );
                                  })}
                              </SegmentedGroup>
                          </div>
                      </div>
                  }
                  {navigation.tab === "diversity" &&
                      <SchoolDiversity
                          locale={locale}
                          dialog={dialog}
                          Dialog={Dialog}
                          account={account}
                      />
                  }

                  {navigation.tab === "information" &&
                      <SchoolInformation
                          locale={locale}
                          account={account}
                      />
                  }
                  {navigation.tab === "about" &&
                      <SchoolAbout
                          locale={locale}
                          account={account}
                      />
                  }
                  {navigation.tab === "location" &&
                      <SchoolLocation
                          locale={locale}
                          account={account}
                          configuration={configuration}
                      />
                  }
                  {navigation.tab === "gallery" &&
                      <SchoolGallery
                          locale={locale}
                          Dialog={Dialog}
                          dialog={dialog}
                          account={account}
                      />
                  }
              </div>
          </div>
      </div>
  );
}

export default School;
