import React, {useMemo} from 'react';
import REST from "./REST";
import Websockets from './Websockets';
import {NetworkContext} from './NetworkContext';

export const NetworkProvider = ({children, locale, session, token, wss}) => {
    const {restResponse, restCall, setRestResponse} = REST(locale, token, session);
    const {wsResponse, wsCall, setWsResponse} = Websockets(locale, token, session, wss);

    const contextValue = useMemo(() => ({
        restCall,
        wsCall,
        restResponse,
        wsResponse,
        setRestResponse,
        setWsResponse
    }), [restCall, wsCall, restResponse, wsResponse, setRestResponse, setWsResponse]);

    return (
        <NetworkContext.Provider value={contextValue}>
            {children}
        </NetworkContext.Provider>
    );
};
