import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Popup, toast, alert, Input} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeadermembership.css';
import Empty from "../utils/Empty";
import DayJS from "react-dayjs";
import {NetworkContext} from "../utils/NetworkContext";

function DashboardHeaderMembership({locale, Dialog, account, setAccount, dialog}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [step, setStep] = useState(0);
    const [amount, setAmount] = useState(550);
    const [processing, setProcessing] = useState(false);
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [expiration, setExpiration] = useState('');
    const [coupon, setCoupon] = useState('');
    const [cvc, setCvc] = useState('');

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Buttons = () => {
        if (step === 0 && !account.stripe.subscription) {

            return [{
                text: locale.dashboardHeaderMembership.z,
                cssClass: 'cs-dashboard-header-membership-save',
                handler: () => CloseDialog()
            }, {
                text: locale.dashboardHeaderMembership.aa,
                cssClass: 'cs-dashboard-header-membership-save',
                handler: () => CouponCheck()
            }]
        } else if (step === 0 && account.stripe.subscription) {

            return [{
                text: locale.dashboardHeaderMembership.z,
                cssClass: 'cs-dashboard-header-membership-save',
                handler: () => CloseDialog()
            }, {
                text: locale.dashboardHeaderMembership.u,
                cssClass: 'cs-dashboard-header-membership-save',
                handler: () => Next()
            }]
        } else if (step === 1 && !account.stripe.subscription) {

            return [{
                text: locale.dashboardHeaderMembership.ae,
                cssClass: 'cs-dashboard-header-membership-save',
                handler: () => Back()
            }, {
                text: locale.dashboardHeaderMembership.n,
                cssClass: 'cs-dashboard-header-membership-save',
                handler: () => Migrate()
            }]
        } else if (step === 1 && account.stripe.subscription) {

            return [{
                text: locale.dashboardHeaderMembership.ae,
                cssClass: 'cs-dashboard-header-membership-save',
                handler: () => Back()
            }, {
                text: locale.dashboardHeaderMembership.u,
                cssClass: 'cs-dashboard-header-membership-save',
                handler: () => Card()
            }]
        }
    }

    const CouponCheck = () => {
        const string = number.replaceAll(' ', '');

        if (!name) {

            toast({message: locale.dashboardHeaderMembership.g, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (string.length !== 16) {

            toast({message: locale.dashboardHeaderMembership.f, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (string && isNaN(string)) {

            toast({message: locale.dashboardHeaderMembership.f, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (!expiration) {

            toast({message: locale.dashboardHeaderMembership.h, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (expiration.length !== 5) {

            toast({message: locale.dashboardHeaderMembership.h, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (!cvc) {

            toast({message: locale.dashboardHeaderMembership.h, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (cvc && isNaN(cvc)) {

            toast({message: locale.dashboardHeaderMembership.h, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (coupon) {

            const message = {
                type: 'wss',
                path: 'coupon',
                action: 'get',
                data: {
                    coupon: coupon
                }
            };
            wsCall(message);
        } else {
            let item= step + 1;
            setStep(item);
        }
    }

    const Back = () => {
        if (step !== 0) {
            let item= step - 1;
            setStep(item);
        }
    }

    const Discount = () => {
        Dialog({view: 'dashboardHeaderDiscount'})
    }

    const Cancel = () => {
        Dialog({view: 'dashboardHeaderCancel'})
    }

    const CC = data => {
        const string = data.replaceAll(' ', '');

        if (string.length <= 4) {
            setNumber(string);
        } else if (string.length > 4 && string.length <= 8) {
            const item = string.slice(0, 4) + ' ' + string.slice(4);
            setNumber(item);
        } else if (string.length > 8 && string.length <= 12) {
            const item = string.slice(0, 4) + ' ' + string.slice(4, 8) + ' ' + string.slice(8, 12) ;
            setNumber(item);
        } else if (string.length > 12 && string.length <= 16) {
            const item = string.slice(0, 4) + ' ' + string.slice(4, 8) + ' ' + string.slice(8, 12) + ' ' + string.slice(12, 16) ;
            setNumber(item);
        }
    }

    const Expiration = data => {
        const string = data.replaceAll('/', '');

        if (string.length < 2) {
            setExpiration(string);
        } else if (string.length === 2) {
            setExpiration(string + '/');
        } else if (string.length > 2 && string.length <= 4) {
            const item = string.slice(0, 2) + '/' + string.slice(2);
            setExpiration(item);
        }
    }

    const CVC = data => {
        if (data.length <= 4) {
            setCvc(data);
        }
    }

    const Migrate = () => {

        const string = number.replaceAll(' ', '');

        if (processing) {

        } else {
            toast({message: locale.dashboardHeaderMembership.m, color: 'info', duration: 3000, display: 'bottom'});
            setProcessing(true);

            const message = {
                type: 'wss',
                path: 'account-stripe',
                action: 'update',
                data: {
                    number: string,
                    name: name,
                    expiration: expiration,
                    cvc: cvc,
                    coupon: coupon
                }
            };
            wsCall(message);
        }
    }

    const Card = () => {

        const string = number.replaceAll(' ', '');

        if (processing) {

        } else if (!name) {

            toast({message: locale.dashboardHeaderMembership.g, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (string.length !== 16) {

            toast({message: locale.dashboardHeaderMembership.f, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (string && isNaN(string)) {

            toast({message: locale.dashboardHeaderMembership.f, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (!expiration) {

            toast({message: locale.dashboardHeaderMembership.h, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (expiration.length !== 5) {

            toast({message: locale.dashboardHeaderMembership.h, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (!cvc) {

            toast({message: locale.dashboardHeaderMembership.h, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (cvc && isNaN(cvc)) {

            toast({message: locale.dashboardHeaderMembership.h, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            toast({message: locale.dashboardHeaderMembership.m, color: 'info', duration: 3000, display: 'bottom'});
            setProcessing(true);

            const message = {
                type: 'wss',
                path: 'account-stripe-card',
                action: 'update',
                data: {
                    number: string,
                    name: name,
                    expiration: expiration,
                    cvc: cvc
                }
            };
            wsCall(message);
        }
    }

    const Next = useCallback(() => {
        let item= step + 1;
        setStep(item);
    }, [step]);

    const UpdateAccountStripeCard = useCallback(data => {
        setProcessing(false);

        if (data.stripe) {
            const item = {...account};
            item.stripe = data.stripe;
            setAccount(item);

            alert({
                title: locale.dashboardHeaderMembership.ap,
                message: locale.dashboardHeaderMembership.aq,
                okText: locale.dashboardHeaderMembership.l,
                callback: () => {
                    CloseDialog();
                }
            });
        }
        if (data.error) {

            toast({message: locale.dashboardHeaderMembership.ar, color: 'danger', duration: 3000, display: 'bottom'});
        }
    }, [account, locale.dashboardHeaderMembership.l, locale.dashboardHeaderMembership.aq, locale.dashboardHeaderMembership.ap, locale.dashboardHeaderMembership.ar]);

    const UpdateAccountStripe = useCallback(data => {
        setProcessing(false);

        if (data.stripe) {
            const item = {...account};
            item.stripe = data.stripe;
            setAccount(item);

            let message = locale.dashboardHeaderMembership.q;

            if (data.warning) {
                message = locale.dashboardHeaderMembership.ao
            }

            alert({
                title: locale.dashboardHeaderMembership.k,
                message: message,
                okText: locale.dashboardHeaderMembership.l,
                callback: () => {
                    CloseDialog();
                }
            });
        }
        if (data.error && data.coupon) {

            toast({message: locale.dashboardHeaderMembership.ad, color: 'danger', duration: 3000, display: 'bottom'});
            setCoupon('');
        } else if (data.error && !data.coupon) {

            toast({message: locale.dashboardHeaderMembership.j, color: 'danger', duration: 3000, display: 'bottom'});
        }
    }, [account, locale.dashboardHeaderMembership.l, locale.dashboardHeaderMembership.q, locale.dashboardHeaderMembership.j, locale.dashboardHeaderMembership.k]);

    const GetCoupon = useCallback(data => {
        if (data.next) {
            Next();
        }
        if (data.amount) {
            setAmount(data.amount);
        }
        if (data.error) {

            toast({message: locale.dashboardHeaderMembership.ad, color: 'danger', duration: 3000, display: 'bottom'});
            setCoupon('');
        }
    }, [Next, locale.dashboardHeaderMembership.ad]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'account-stripe') {
            UpdateAccountStripe(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateAccountStripe, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'account-stripe-card') {
            UpdateAccountStripeCard(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateAccountStripeCard, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'coupon') {
            GetCoupon(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetCoupon, setWsResponse]);

    return (
        <Popup className="cs-dashboard-header-membership-popup" width={600} closeOnOverlayClick={false} closeOnEsc={false} maxHeight={800} scrollLock={false} theme="ios" themeVariant="light" display="center" headerText={locale.dashboardHeaderMembership.a} buttons={Buttons()} isOpen={dialog.view === 'dashboardHeaderMembership'} onClose={CloseDialog}>
            {step === 0 && !account.stripe.subscription &&
                <>
                    <div className="cs-dashboard-header-membership-helper">
                        <p className="mbsc-txt-muted">
                            {locale.dashboardHeaderMembership.o} <span className="mbsc-bold"><DayJS format="MMMM D, YYYY">{account.stripe.expire}</DayJS></span>.
                        </p>
                    </div>
                    <div className="cs-dashboard-header-membership-helper">
                        <p className="mbsc-txt-muted">
                            {locale.dashboardHeaderMembership.ac}
                        </p>
                    </div>
                    <div className="cs-dashboard-header-membership-helper">
                        <h5 className="mbsc-txt-muted cs-dashboard-header-membership-title">
                            {locale.dashboardHeaderMembership.i}
                        </h5>
                    </div>
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.dashboardHeaderMembership.b} placeholder={locale.dashboardHeaderMembership.v} labelStyle="floating" type="text" name="name" value={name} onChange={ev => setName(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.dashboardHeaderMembership.c} placeholder={locale.dashboardHeaderMembership.w} labelStyle="floating" type="text" name="number" value={number} onChange={ev => CC(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.dashboardHeaderMembership.d} placeholder={locale.dashboardHeaderMembership.r} labelStyle="floating" type="text" name="expiration" value={expiration} onChange={ev => Expiration(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.dashboardHeaderMembership.e} placeholder={locale.dashboardHeaderMembership.x} labelStyle="floating" type="text" name="cvc" value={cvc} onChange={ev => CVC(ev.target.value)} />
                    <div className="cs-dashboard-header-membership-helper">
                        <p className="mbsc-txt-muted">
                            {locale.dashboardHeaderMembership.ab}
                        </p>
                    </div>
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.dashboardHeaderMembership.y} labelStyle="floating" type="text" name="coupon" value={coupon} onChange={ev => setCoupon(ev.target.value)} />
                    <div className="cs-dashboard-header-membership-helper">
                        <p className="mbsc-txt-muted">
                            {locale.dashboardHeaderMembership.s} <a className="cs-dashboard-header-membership-links" href="#" onClick={Discount}>{locale.dashboardHeaderMembership.t}</a>
                        </p>
                    </div>
                </>
            }
            {step === 0 && account.stripe.subscription &&
                <>
                    <div className="cs-dashboard-header-membership-helper">
                        <p className="mbsc-txt-muted">
                            {locale.dashboardHeaderMembership.o} <span className="mbsc-bold"><DayJS format="MMMM D, YYYY">{account.stripe.periodEnd}</DayJS></span>
                        </p>
                    </div>
                    <div className="cs-dashboard-header-membership-helper">
                        <p className="mbsc-txt-muted">
                            {locale.dashboardHeaderMembership.p}
                        </p>
                    </div>
                    <Input disabled theme="ios" themeVariant="light" inputStyle="underline" label={locale.dashboardHeaderMembership.c} placeholder={locale.dashboardHeaderMembership.w} labelStyle="floating" type="text" name="number" value={'XXXX XXXX XXXXX ' + account.stripe.last4} />
                    <Input disabled theme="ios" themeVariant="light" inputStyle="underline" label={locale.dashboardHeaderMembership.d} placeholder={locale.dashboardHeaderMembership.r} labelStyle="floating" type="text" name="expiration" value={account.stripe.month + '/' + account.stripe.year} />
                    <div className="cs-dashboard-header-membership-helper">
                        <p className="mbsc-txt-muted">
                            {locale.dashboardHeaderMembership.al}
                        </p>
                    </div>
                    <div className="cs-dashboard-header-membership-helper">
                        <p className="mbsc-txt-muted">
                            {locale.dashboardHeaderMembership.am} <a className="cs-dashboard-header-membership-links" href="#" onClick={Cancel}>{locale.dashboardHeaderMembership.t}</a>
                        </p>
                    </div>
                </>
            }
            {step === 1 && !account.stripe.subscription &&
                <>
                    <div className="cs-dashboard-header-membership-helper">
                        <p className="mbsc-txt-muted mbsc-bold">
                            {coupon ? locale.dashboardHeaderMembership.af : locale.dashboardHeaderMembership.ag}{amount.toFixed(2)} {locale.dashboardHeaderMembership.ah}.
                        </p>
                    </div>
                    <div className="cs-dashboard-header-membership-helper">
                        <p className="mbsc-txt-muted">
                            {locale.dashboardHeaderMembership.ai}
                        </p>
                    </div>
                    <div className="cs-dashboard-header-membership-helper">
                        <p className="mbsc-txt-muted">
                            {locale.dashboardHeaderMembership.aj} <a href="https://www.diversityfair.org/tcpp" target="_blank">{locale.dashboardHeaderMembership.ak}</a>
                        </p>
                    </div>
                </>
            }
            {step === 1 && account.stripe.subscription &&
                <>
                    <div className="cs-dashboard-header-membership-helper">
                        <p className="mbsc-txt-muted">
                            {locale.dashboardHeaderMembership.an}
                        </p>
                    </div>
                    <div className="cs-dashboard-header-membership-helper">
                        <h5 className="mbsc-txt-muted cs-dashboard-header-membership-title">
                            {locale.dashboardHeaderMembership.i}
                        </h5>
                    </div>
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.dashboardHeaderMembership.b} placeholder={locale.dashboardHeaderMembership.v} labelStyle="floating" type="text" name="name" value={name} onChange={ev => setName(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.dashboardHeaderMembership.c} placeholder={locale.dashboardHeaderMembership.w} labelStyle="floating" type="text" name="number" value={number} onChange={ev => CC(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.dashboardHeaderMembership.d} placeholder={locale.dashboardHeaderMembership.r} labelStyle="floating" type="text" name="expiration" value={expiration} onChange={ev => Expiration(ev.target.value)} />
                    <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.dashboardHeaderMembership.e} placeholder={locale.dashboardHeaderMembership.x} labelStyle="floating" type="text" name="cvc" value={cvc} onChange={ev => CVC(ev.target.value)} />
                </>
            }
        </Popup>

    );
}

export default DashboardHeaderMembership;
