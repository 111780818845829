import React, {useState, useContext, useEffect, useCallback} from 'react';
import {SegmentedGroup, Segmented, Input, Select, Button, toast, Checkbox} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/sharedCandidate.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHeartCirclePlus, faHeartCircleMinus, faFilePdf, faCloudArrowDown, faPaperPlane} from "@fortawesome/pro-duotone-svg-icons";
import Empty from '../utils/Empty';
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CandidateReferral from "../dialogs/CandidateReferral";
import {NetworkContext} from "../utils/NetworkContext";

function Candidate({locale, candidate, candidateData, configuration, width, Dialog, dialog, Navigation, navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [favorite, setFavorite] = useState(candidateData.notes.favorite);
    const [notes, setNotes] = useState(candidateData.notes);
    const [referral, setReferral] = useState({});

    const Notes = data => {
        const item = {...notes};
        if (data === 'favorite') {
            if (item.favorite === false) {
                item.favorite = true;
                setFavorite(true);
            } else {
                item.favorite = false;
                setFavorite(false);
            }
        }
        setNotes(item);

        const message = {
            type: 'wss',
            path: 'user-notes',
            action: 'put',
            data: {
                private: item,
                shared: candidateData.notesShared,
                candidate: candidate.email,
                avatar: candidate.avatar,
                firstName: candidate.firstName,
                lastName: candidate.lastName
            }
        };
        wsCall(message);
    }

    const Tab = data => {
        Navigation({item: data.target.value});
    }

    const Referral = data => {
        setReferral(candidate.referrals[data]);
        Dialog({view: 'candidateReferral', tab: 'general'});
    }

    const DocumentItem = data => {
        return <li>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-shared-candidate-list">
                        <FontAwesomeIcon className="cs-shared-candidate-icon-referral" icon={faFilePdf} />
                        <div className="cs-shared-candidate-file">
                            <div className="mbsc-row">
                                <h4 className="cs-shared-candidate-file-text">{data.item.tag === 'resume' && locale.sharedCandidate.k}{data.item.tag === 'philosophy' && locale.sharedCandidate.l}{data.item.tag === 'statement' && locale.sharedCandidate.m}{data.item.tag === 'other' && locale.sharedCandidate.i}</h4>
                            </div>
                            <div>
                                {data.item.fileName}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mbsc-col-auto cs-shared-candidate-file-button">
                    <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Download(data.item)} >
                        {width > 992 && locale.sharedCandidate.n} <FontAwesomeIcon className="cs-shared-candidate-icon-upload" icon={faCloudArrowDown} />
                    </Button>
                </div>
            </div>
        </li>;
    }

    const ReferralItem = data => {
        return <li>
            <div className="mbsc-row">
                <div className="mbsc-col">
                    <div className="mbsc-row cs-shared-candidate-name">
                        <FontAwesomeIcon className="cs-shared-candidate-icon-referral" icon={faPaperPlane} />
                        <div>
                            {(data.item.firstName || data.item.lastName) ?
                                <div className="mbsc-bold">
                                    <span>{data.item.firstName && data.item.firstName} {data.item.lastName && data.item.lastName}</span>
                                </div>
                                :
                                <div className="mbsc-bold">
                                    <span>{data.item.email}</span>
                                </div>
                            }
                            {(data.item.firstName || data.item.lastName) &&
                                <div className="mbsc-txt-s mbsc-txt-muted cs-shared-candidate-detail">
                                    {data.item.email}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </li>;
    }

    const Download = data => {
        const message = {
            type: 'wss',
            path: 'user-file',
            action: 'get',
            data: data
        };
        wsCall(message);
    }

    const GetUserFile = useCallback(data => {
        if (data.toast) {
            toast({message: locale.sharedCandidate.j, color: 'info', duration: 1000, display: 'bottom'});
        }
        if (data.url) {
            const a = document.createElement('a');
            a.href = data.url;
            a.click();
        }
    }, [locale.sharedCandidate.j]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'user-file') {
            GetUserFile(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetUserFile, setWsResponse]);

    useEffect(() => {
        if (navigation.menu !== locale.sharedCandidate.az) {
            Navigation({menu: locale.sharedCandidate.az});
        } else if (!navigation.item) {
            Navigation({item: 'information'});
        }
    }, []);

    return (
        <>
            <div className="mbsc-row cs-shared-candidate-main">
                <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + candidate.avatar + "?d=robohash)"}} className="cs-shared-candidate-avatar" />
                <div>
                    <div>
                        <h4 className="cs-shared-candidate-header-title">{candidate.firstName} {candidate.lastName}</h4>
                    </div>
                    <div>
                        {candidate.email && candidate.email}
                        {candidate.country && ' | '}
                        {candidate.country && candidate.country}
                    </div>
                </div>
            </div>
            <div className="mbsc-row cs-shared-candidate-main">
                <div className="mbsc-col">
                    <SegmentedGroup theme="ios" themeVariant="light" className="cs-shared-candidate-nav" name="candidate" color="primary" value={navigation.item} onChange={Tab}>
                        <Segmented value="information" theme="ios" themeVariant="light" >
                            {locale.sharedCandidate.a}
                        </Segmented>
                        {candidate.bio &&
                            <Segmented value="bio" theme="ios" themeVariant="light" >
                                {locale.sharedCandidate.b}
                            </Segmented>
                        }
                        <Segmented value="job" theme="ios" themeVariant="light" >
                            {locale.sharedCandidate.c}
                        </Segmented>
                        {(candidate.spouse.firstName || candidate.spouse.lastName || candidate.spouse.gender || candidate.spouse.citizenship || candidate.spouse.dependents) &&
                            <Segmented value="spouse" theme="ios" themeVariant="light" >
                                {locale.sharedCandidate.f}
                            </Segmented>
                        }
                        {candidate.documents.length !== 0 &&
                            <Segmented value="documents" theme="ios" themeVariant="light" >
                                {locale.sharedCandidate.g}
                            </Segmented>
                        }
                        {candidate.referrals.length !== 0 &&
                            <Segmented value="referrals" theme="ios" themeVariant="light" >
                                {locale.sharedCandidate.h}
                            </Segmented>
                        }
                    </SegmentedGroup>
                </div>
            </div>
            {navigation.item === 'information' &&
                <div className="mbsc-row cs-shared-candidate-main">
                    <div className="mbsc-col cs-shared-candidate-col">
                        {candidate.mobile &&
                            <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.sharedCandidate.s} labelStyle="floating" type="text" name="mobile" value={candidate.mobile} />
                        }
                        {candidate.gender &&
                            <Select
                                theme="ios"
                                themeVariant="light"
                                disabled
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.sharedCandidate.t}
                                labelStyle="floating"
                                rows={locale.sharedCandidate.ba.length}
                                display="anchored"
                                touchUi={false}
                                value={candidate.gender}
                                data={locale.sharedCandidate.ba}
                            />
                        }
                        <Checkbox
                            theme="ios"
                            themeVariant="light"
                            disabled
                            cssClass="cs-shared-candidate-checkbox mbsc-bold"
                            color="info"
                            position="start"
                            label={locale.sharedCandidate.p}
                            checked={candidate.lgbtqia}
                        />
                        {candidate.ethnicity &&
                            <Select
                                theme="ios"
                                themeVariant="light"
                                disabled
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.sharedCandidate.x}
                                labelStyle="floating"
                                rows={locale.sharedCandidate.bb.length}
                                display="anchored"
                                touchUi={false}
                                value={candidate.ethnicity}
                                data={locale.sharedCandidate.bb}
                            />
                        }
                        {candidate.primaryCitizenship &&
                            <Select
                                theme="ios"
                                themeVariant="light"
                                disabled
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.sharedCandidate.o}
                                labelStyle="floating"
                                rows={10}
                                display="anchored"
                                touchUi={false}
                                value={candidate.primaryCitizenship}
                                data={configuration.mobi.countries}
                            />
                        }
                    </div>
                </div>
            }
            {navigation.item === 'bio' &&
                <div className="cs-shared-candidate-col-ck mbsc-padding">
                    <CKEditor id="editor-focus" editor={ClassicEditor} config={{toolbar: []}} disabled={true} data={candidate.bio} />
                </div>
            }
            {navigation.item === 'job' &&
                <div className="mbsc-row cs-shared-candidate-main">
                    <div className="mbsc-col cs-shared-candidate-col">
                        <Checkbox
                            theme="ios"
                            themeVariant="light"
                            disabled
                            cssClass="cs-shared-candidate-checkbox mbsc-bold"
                            color="info"
                            position="start"
                            label={locale.sharedCandidate.am}
                            description={locale.sharedCandidate.d}
                            checked={candidate.teacher}
                        />
                        <Checkbox
                            theme="ios"
                            themeVariant="light"
                            disabled
                            cssClass="cs-shared-candidate-checkbox mbsc-bold"
                            color="info"
                            position="start"
                            label={locale.sharedCandidate.an}
                            description={locale.sharedCandidate.e}
                            checked={candidate.leader}
                        />
                        {candidate.job &&
                            <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.sharedCandidate.aj} labelStyle="floating" type="text" name="mobile" value={candidate.job} />
                        }
                        {candidate.school &&
                            <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.sharedCandidate.ak} labelStyle="floating" type="text" name="mobile" value={candidate.school} />
                        }
                        {candidate.division &&
                            <Select
                                theme="ios"
                                themeVariant="light"
                                disabled
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.sharedCandidate.ae}
                                labelStyle="floating"
                                rows={locale.sharedCandidate.bc.length}
                                display="anchored"
                                touchUi={false}
                                value={candidate.division}
                                data={locale.sharedCandidate.bc}
                            />
                        }
                    </div>
                </div>
            }
            {navigation.item === 'spouse' &&
                <div className="mbsc-row cs-shared-candidate-main">
                    <div className="mbsc-col cs-shared-candidate-col">
                        {candidate.spouse.firstName &&
                            <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.sharedCandidate.au} labelStyle="floating" type="text" name="firstName" value={candidate.spouse.firstName} />
                        }
                        {candidate.spouse.lastName &&
                            <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.sharedCandidate.av} labelStyle="floating" type="text" name="lastName" value={candidate.spouse.lastName} />
                        }
                        {candidate.spouse.gender &&
                            <Select
                                theme="ios"
                                themeVariant="light"
                                disabled
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.sharedCandidate.aq}
                                labelStyle="floating"
                                rows={locale.sharedCandidate.ba.length}
                                display="anchored"
                                touchUi={false}
                                value={candidate.spouse.gender}
                                data={locale.sharedCandidate.ba}
                            />
                        }
                        {candidate.spouse.citizenship &&
                            <Select
                                theme="ios"
                                themeVariant="light"
                                disabled
                                animation="slide-down"
                                dropdown={false}
                                inputStyle="outline"
                                label={locale.sharedCandidate.ap}
                                labelStyle="floating"
                                rows={10}
                                display="anchored"
                                touchUi={false}
                                value={candidate.spouse.citizenship}
                                data={configuration.mobi.countries}
                            />
                        }
                        {candidate.spouse.firstName && candidate.spouse.lastName &&
                            <Checkbox
                                theme="ios"
                                themeVariant="light"
                                disabled
                                cssClass="cs-shared-candidate-checkbox mbsc-bold"
                                color="info"
                                position="start"
                                label={locale.sharedCandidate.ao}
                                checked={candidate.spouse.teaching}
                            />
                        }
                        {candidate.spouse.dependents &&
                            <Input disabled theme="ios" themeVariant="light" inputStyle="outline" label={locale.sharedCandidate.aw} labelStyle="floating" type="text" name="dependents" value={candidate.spouse.dependents} />
                        }
                    </div>
                </div>
            }
            {navigation.item === 'documents' &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    itemType={DocumentItem}
                    data={candidate.documents}
                />
            }
            {navigation.item === 'referrals' &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    itemType={ReferralItem}
                    data={candidate.referrals}
                    onItemTap={event => Referral(event.index)}
                />
            }
            {(navigation.item === 'information' || navigation.item === 'bio' || navigation.item === 'job' || navigation.item === 'spouse') &&
                <div className="mbsc-row cs-shared-candidate-footer">
                    <Button color="primary" variant="flat" theme="ios" themeVariant="light" className="mbsc-bold" onClick={() => Notes('favorite')} >
                        <FontAwesomeIcon className="cs-shared-candidate-button-icon" icon={favorite === false ? faHeartCirclePlus : faHeartCircleMinus} /> {favorite === false ? locale.sharedCandidate.ax : locale.sharedCandidate.ay}
                    </Button>
                </div>
            }
            {dialog.view === 'candidateReferral' &&
                <CandidateReferral
                    locale={locale}
                    Dialog={Dialog}
                    dialog={dialog}
                    referral={referral}
                />
            }
        </>
    );
}

export default Candidate;
