import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Input, Popup, Textarea, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/userReferral.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

function UserReferral({locale, Dialog, dialog, user, setUser}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [text, setText] = useState('');

    const CloseDialog = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Save = () => {
        const array = user.referrals;
        let item = false;
        if (array.length !== 0) {
            array.some(itemReferral => {
                if (itemReferral.email === email) {
                    item = true;

                    return true;
                }
                return false;
            });
        }
        if (item) {
            toast({message: locale.userReferral.f, color: 'danger', duration: 3000, display: 'bottom'});
        } else if (!email) {
            toast({message: locale.userReferral.g, color: 'danger', duration: 3000, display: 'bottom'});
        } else if ( !firstName || !lastName ) {
            toast({message: locale.userReferral.h, color: 'danger', duration: 3000, display: 'bottom'});
        } else {
            const message = {
                type: 'wss',
                path: 'user-referral',
                action: 'put',
                data: {
                    email: email,
                    firstName: firstName,
                    lastName: lastName,
                    text: text
                }
            };
            wsCall(message);
        }
    }

    const PutUserReferral = useCallback(data => {
        if (data.toast) {
            toast({message: locale.userReferral.m, color: 'info', duration: 1000, display: 'bottom'});
            CloseDialog();
        }
        if (data.referrals) {
            const item = {...user};
            item.referrals = data.referrals;
            setUser(item);
        }
    }, [locale.userReferral.m, user, CloseDialog]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'user-referral') {
            PutUserReferral(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutUserReferral, setWsResponse]);

    return (
        <Popup className="cs-user-referral-popup" width={600} maxHeight={700} closeOnOverlayClick={false} closeOnEsc={false} theme="ios" themeVariant="light" display="center" headerText={locale.userReferral.d} buttons={[{text: locale.userReferral.k, cssClass: 'cs-user-referral-cancel', handler: () => CloseDialog()},{text: locale.userReferral.l, cssClass: 'cs-user-referral-save', handler: () => Save()}]} isOpen={dialog.view === 'userReferral'} onClose={CloseDialog}>
            <div className="cs-user-referral-helper">
                <p className="mbsc-txt-muted">
                    {locale.userReferral.i}
                </p>
            </div>
            <div className="cs-user-referral-helper">
                <p className="mbsc-txt-muted">
                    {locale.userReferral.j}
                </p>
            </div>
            <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.userReferral.a} labelStyle="floating" type="email" name="email" value={email} onChange={ev => setEmail(ev.target.value)} />
            <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.userReferral.b} labelStyle="floating" type="text" name="firstName" value={firstName} onChange={ev => setFirstName(ev.target.value)} />
            <Input theme="ios" themeVariant="light" inputStyle="underline" label={locale.userReferral.c} labelStyle="floating" type="text" name="lastName" value={lastName} onChange={ev => setLastName(ev.target.value)} />
            <div className="cs-user-referral-textarea">
                <Textarea theme="ios" themeVariant="light" inputStyle="underline" labelStyle="floating" label={locale.userReferral.e} value={text} onChange={(ev) => setText(ev.target.value)} />
            </div>
            <div className="cs-user-referral-helper">
                <p className="mbsc-txt-muted">
                    {locale.userReferral.n}
                </p>
            </div>
        </Popup>
    );
}

export default UserReferral;
